import {useFormikContext} from "formik";
import * as React from "react";
import {AllianzLifeCarrierDetail} from "../../../../../constants";
import { useCheckAtlantic } from "../../../../../hooks/useAuth";

export default function FormikTokenUpdate(props: any) {
    const {getMinContribution} = props;
    const atlantic = useCheckAtlantic(); 
    const { values: { gender, tobacco, healthType, issueAge, carrierId, contributionFrequency } }:any = useFormikContext();
    React.useEffect(() => {
        let health: string = healthType || "Standard";
        let tobaccoCons: string = tobacco === "used" ? "Tobacco" : "NT";
        let rateClass: string = `${health}_${tobaccoCons}`;
        if (tobaccoCons === "NT" && healthType === "Preferred" && carrierId === AllianzLifeCarrierDetail.value) {
            rateClass = "Elite_NT";
        }
        let frequency: string = contributionFrequency && contributionFrequency.toLowerCase() || null;
        if(!!rateClass && gender && issueAge && frequency) {
            let query:any = { rateClass, gender, issueAge, carrierId, frequency };
            if (atlantic){
                query = { rateClass, gender, issueAge, carrierId, frequency, groupId: "atlantic" };
            }
            getMinContribution(query).then(() => {});
        }
    }, [gender, tobacco, healthType, issueAge, contributionFrequency]);
    return null;
};