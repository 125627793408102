import React from 'react';
import {Formik} from "formik";
import PremiumFinancingQuestions from "./PremiumFinancingQuestions";
import {Button, Grid, Typography} from "@material-ui/core";
import {longFormFinancialQuestions} from "../FinancialAgreement/FinancialQuestions";
import * as Yup from "yup";
import {payloadDataHandler} from "../index";
import FocusError from "../../../../../../../components/FocusError";
import {advisorInformation3rdParty} from "../FinancialAgreement/FinancialQuestions";
import {getRelatedKeysFromObject} from "../../../../../../../utils/commonUtils";

type PropsType = {
    autoSaveTrigger: (event: React.FocusEvent<HTMLFormElement>) => void;
    clientDetail:any,
    isFromClientDetail:boolean,
    offerType:string,
    premiumFinancingRefs:any,
    setFinancialAndLiabilitiesArr: Function,
    financialAndLiabilitiesArr: any,
    sendSaveRequest:Function
}

const REQUIRED_MESSAGE:string = "Required Field";
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const validateFQ10 = (schemaType:string) => {
    switch (schemaType) {
        case "string":
            return Yup.string().when("FinancialQuestionsXR_10", {
                is: "Yes",
                then :Yup.string().matches(/^[A-Za-z0-9\s]*$/,"Should be in Alphanumeric Only").required(REQUIRED_MESSAGE).nullable(),
            });
            break;
        case "city":
            return Yup.string().when("FinancialQuestionsXR_10", {
                is: "Yes",
                then :Yup.string().matches(/^[A-Za-z\s]*$/,"Should be in Alphabet Only").required(REQUIRED_MESSAGE).nullable(),
            });
            break;
        case "state":
            return Yup.string().when("FinancialQuestionsXR_10", {
                is: "Yes",
                then :Yup.string().matches(/^[A-Za-z\s]*$/,"Should be in Alphabet Only").required(REQUIRED_MESSAGE).nullable(),
            });
            break;

        case "zipcode":
            return Yup.string().when("FinancialQuestionsXR_10", {
                is: "Yes",
                then: Yup.string().required(REQUIRED_MESSAGE).matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please enter a valid zip code"),
            });
            break;
        case "numeric":
            return Yup.string().when("FinancialQuestionsXR_10", {
                is: "Yes",
                then: Yup.string().required(REQUIRED_MESSAGE).matches(phoneRegExp, "Should be in Number Only"),
            });
            break;

        default:
            return Yup.string();
    }
}

function PremiumFinancing (props: PropsType) {
    const {
        autoSaveTrigger,
        clientDetail,
        financialAndLiabilitiesArr,
        isFromClientDetail,
        offerType,
        premiumFinancingRefs,
        sendSaveRequest,
        setFinancialAndLiabilitiesArr,
    } = props;
    const clientSigned = clientDetail && clientDetail.enrollment && (clientDetail.enrollment.clientSigned || clientDetail.enrollment.envelopeId) ? true : false;
    let initialValues = {};
    const keysValidation = {};

    const financialQuestion:any = longFormFinancialQuestions({offerType});
    let answersJson:any = clientDetail && clientDetail.enrollment && clientDetail.enrollment.answers ? JSON.parse(clientDetail.enrollment.answers) : [];

    const includeValidationFQ10 = (fieldObj:any[]) => {
        if(fieldObj.length > 0) {
            fieldObj.forEach((fi) => {
                keysValidation[fi.name] = validateFQ10(fi.fieldType)
            })
        }
    }

    financialQuestion.forEach((field) => {
        initialValues[field.name] = answersJson[field.name] || null;
        keysValidation[field.name] = Yup.string().required("Required").nullable();
        if(field.name === 'FinancialQuestionsXR_10') {
            const fieldObj:any[] = advisorInformation3rdParty({clientSigned, offerType})
            includeValidationFQ10(fieldObj)
        }
    });

    if(answersJson.hasOwnProperty('FinancialQuestionsXR_10') && answersJson['FinancialQuestionsXR_10'] === "Yes"){
        const fq10:any = getRelatedKeysFromObject("FinancialQuestionsXT_10", answersJson);
        Object.assign(initialValues, fq10);
    }

    const validationSchema = Yup.object().shape(keysValidation);

    return (
            <Formik
                    innerRef={premiumFinancingRefs}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values: any, { setSubmitting }) => {
                        try {
                            let payload:any = payloadDataHandler(values, "PREMIUM_FINANCING", false);
                            const index = financialAndLiabilitiesArr.findIndex((item) => item.formType === "PREMIUM_FINANCING")
                            if(index > -1) {
                                financialAndLiabilitiesArr.splice(index, 1);
                            }
                            setFinancialAndLiabilitiesArr([...financialAndLiabilitiesArr, {
                                ...payload
                            }]);
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        } catch (e) {
                            return false;
                        }
                    }}
            >
                {
                    ({
                        handleSubmit
                        }) => {
                        return (
                            <form className="mt20 floatLeft w100" onBlur={autoSaveTrigger} onSubmit={handleSubmit}>
                                <FocusError offset={true}/>
                                <Grid item xs={12}>
                                    <Typography variant="body1" component="strong" className="floatLeft w100 mb10">
                                        Premium Financing Disclosure Questions
                                    </Typography>
                                    <Typography variant="body2" className="mb30">
                                        You have applied for life insurance from Allianz Life insurance Company of North America (“Allianz”). “You” or “Your” as used herein refers to the Proposed Insured and the Trustee (if a trust will be the owner of the policy). You have indicated to Allianz that it is your intention to finance the premiums that are payable for this Allianz policy. With respect to your premium finance arrangement, did your Financial Professional(s) explain the following (Check your response):
                                    </Typography>
                                </Grid>
                                <span className="hLine"/>
                                <PremiumFinancingQuestions user={clientDetail} isFromClientDetail={isFromClientDetail} offerType={offerType} sendSaveRequest={sendSaveRequest}/>
                                <Button
                                        variant="contained"
                                        color="primary"
                                        className="detailPageSubmitBtn"
                                        type={"submit"}
                                        style={{
                                            display: "none",
                                        }}
                                >
                                </Button>
                            </form>
                        )
                        }}
            </Formik>
    )
}
export default PremiumFinancing;