import {Box, Card, CardContent, Grid} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {useActions} from "../../../actions";
import * as Actions from "../../../actions/account";
import AddImoAccount from "../../../components/AddImoAccount";
import {RootState} from "../../../reducers";
import {unmaskPhoneNumber} from "../../../utils/commonUtils";
import {UpdateButton, updateImoForm} from "../../EditAccountPage";
import {can} from "../../../components/navigation/AdminNavigation";
import {AccountType, PermissionTypes} from "../../../constants";


type ImoProfileTabProps = {
    userId: number
}

type ImoFormData = {

}

const ImoProfileTab = (props) => {
    const dispatch = useDispatch()
    const accountActions = useActions(Actions);
    const account = useSelector((state: RootState) => state.account);
    const {user: {role}} = useSelector((state: RootState) => state.auth);
    const auth = useSelector((state: RootState) => state.auth);
    const { handleSubmit, initialValues, pristine, submitting, userId } = props
    const disable = role === AccountType.Aggregator;
    // TODO: uncomment this
    // React.useEffect(() => {
    //     carrierAction.getAllCarriers();
    //     accountActions.getAllStrategy();
    // }, [userId]);

    const [isToggle, setIsToggle] = React.useState<boolean>(false);
    const [imoCarriers, setImoCarriers] = React.useState<any>([]);

    React.useEffect(()=>{
        setImoCarriers((account && account.imo && account.imo.carriers) || [])
    }, [account])

    const updateAccount = async (data: any) => {
        delete data.id;
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber);
        }

        if (data.imo && data.imo.officeNumber) {
            data.imo.officeNumber = unmaskPhoneNumber(data.imo.officeNumber);
        }

        data = updateImoForm(data, initialValues);
        await accountActions.updateAdminImoDetails(data, userId);
    };

    return (
        <Box>
            <Card className="muiCard">
                <CardContent className="muiCardContent">
                    <form onSubmit={handleSubmit(updateAccount)}>
                        <Grid container spacing={2}>
                            <AddImoAccount
                                page={"ImoProfileTab"}
                                carriers={imoCarriers}
                                initialReBrand={initialValues.imo && initialValues.imo.reBrand}
                                initialReBrandLogo={initialValues.imo && initialValues.imo.reBrandLogo}
                                dispatch={dispatch}
                            />
                        </Grid>
                        <Grid>
                            {
                                auth.user && (can(auth.user, PermissionTypes.ACCOUNT_MANAGEMENT) && (auth.user.role === 1 || auth.user.role === 2)) &&
                                    <UpdateButton
                                        isUpdated={false}
                                        pristine={pristine}
                                        submitting={submitting}
                                        setIsToggle={setIsToggle}
                                        disabled={disable}
                                    />
                            }
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Box>
    )
}

export let ImoProfileTabReduxForm = reduxForm<ImoFormData, ImoProfileTabProps>({
    form: "ImoProfileTab",
    enableReinitialize: true
})(ImoProfileTab)
