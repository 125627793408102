import React, {createContext, useContext, useEffect, useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers";
import {useActions} from "../../../actions";
import * as PaymentActions from "../../../actions/payment";
import {ATLANTIC_HEALTH} from "../../../constants"


const PolicyContext = createContext({});


async function getPolicyDetails(payment: any, clientData: any) {
    const {policyNumber, contributionAmount, fundingDate, policyNumberCarrier, trancheNumber, carrier, clientName, clientUsername, bankAmount} = clientData;
    if (policyNumber && contributionAmount && fundingDate) {
        const paymentPayload = {
            "policyNumber": policyNumber,
            "policyNumberCarrier": policyNumberCarrier,
            "trancheNumber": trancheNumber + "",
            "carrier": carrier,
            "clientName": clientName,
            "clientUsername": clientUsername,
            "clientAnnualAmount": contributionAmount,
            "bankAnnualAmount": bankAmount,
            "employerAnnualAmount": 0,
            "trustFeeAnnualAmount": 1350,
            "clientPaymentYears": [1, 2, 3, 4, 5],
            "bankPaymentYears": [1, 2, 3, 4, 5, 6, 7, 8 , 9, 10],
            "employerPaymentYears": [1,2,3,4,5],
            "policyStartDate": moment(fundingDate).format("YYYY-MM-DD")
        }
        return await payment.policyDetails(paymentPayload);
    } else {
        return;
    }
}

// Create a provider component
export const PolicyContextProvider = (props: any) => {
    const {clientData = {}} = props;
    const [policy, setPolicy] = useState(null);
    const clientDetail = useSelector((state: RootState) => state.profile);
    let contributionAmount: number = 0;
    let bankAmount: number = 0;
    let policyNumber: string;
    let fundingDate: string;
    let policyNumberCarrier: string | undefined;
    let trancheNumber: string;
    let carrier: string;
    let clientName: string;
    let clientUsername: string;
    if (clientData && clientData.issuedContribution) {
        if (ATLANTIC_HEALTH.includes(clientData.company && clientData.company.companyLinkUrl)) {
            contributionAmount = clientData.issuedContribution - clientData.employerContribution;
        } else {
            contributionAmount = clientData.issuedContribution;
        }
        bankAmount = (clientData.annualPremium - clientData.issuedContribution) + 1350;
        policyNumber = clientData.clientPolicies && clientData.clientPolicies[0].id
        fundingDate = clientData.tranche && clientData.tranche.projectFundingDate;
        trancheNumber = clientData.tranche && clientData.tranche.trancheNumber || "";
        policyNumberCarrier = clientData.clientPolicies && clientData.clientPolicies[0].policyNumber || "";
        carrier = clientData.carrier && clientData.carrier.carrierName || "";
        clientName = clientData.name;
        clientUsername = clientData.email;
    } else {
        if (clientDetail && clientDetail.client) {
            const issuedContribution = clientDetail.client.issuedContribution || 0;
            const employerContribution = clientDetail.client.employerContribution || 0;
            const annualPremium = clientDetail.client.annualPremium || 0;
            if (ATLANTIC_HEALTH.includes(clientDetail.client && clientDetail.client.company && clientDetail.client.company.companyLinkUrl || "")) {
                contributionAmount = issuedContribution - employerContribution;
            } else {
                contributionAmount = issuedContribution;
            }
            bankAmount = (annualPremium - issuedContribution) + 1350;
            policyNumber = clientDetail.client.clientPolicies && clientDetail.client.clientPolicies[0].id || "";
            fundingDate = clientDetail.client.tranche && clientDetail.client.tranche.projectFundingDate;
            trancheNumber = clientDetail.client.tranche && clientDetail.client.tranche.trancheNumber || "";
            policyNumberCarrier = clientDetail.client.clientPolicies && clientDetail.client.clientPolicies[0].policyNumber || "";
            carrier = clientDetail.client.carrier && clientDetail.client.carrier.carrierName || "";
            clientName = clientDetail.firstName + ' ' + clientDetail.lastName;
            clientUsername = clientDetail.email;
        }

    }
    const payment = useActions(PaymentActions);
    useEffect(() => {
        (async () => {
            if (contributionAmount && policyNumber) {
                let policyDetails = await getPolicyDetails(payment, {contributionAmount, policyNumber, fundingDate, policyNumberCarrier, trancheNumber, carrier, clientName, clientUsername, bankAmount});
                if (policyDetails) {
                    setPolicy(policyDetails);
                }
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);
    const updatePolicy = (newPolicy: any) => {
        setPolicy(newPolicy);
    };

    const refetchPolicy = async () => {
        let policyDetails = await getPolicyDetails(payment, {contributionAmount, policyNumber, fundingDate, policyNumberCarrier, trancheNumber, carrier, clientName, clientUsername, bankAmount});
        if (policyDetails) {
            setPolicy(policyDetails);
        }
    };

    return (
        <PolicyContext.Provider value={{ policy, updatePolicy, refetchPolicy }}>
            {props.children}
        </PolicyContext.Provider>
    );
};

export const usePolicyContext = () => {
    const context: any = useContext(PolicyContext);
    if (!context) {
        throw new Error('usePolicyContext must be used within a PolicyContextProvider');
    }
    if (context.loading) {
        return "Loading.."
    }
    return context;
};