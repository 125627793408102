import {
    Grid,
    Typography, IconButton, Tooltip,
} from "@material-ui/core";
import * as React from "react";
import { Label } from "../../../../../components/formInputs/index";
import {
    EstimationErrorCommon,
    TextFieldCommon,
    DatePicker,
    SelectField,
    TextField,
} from "../../../../../components/formikFormInputs";
import EstimationFormContext from "../Context/EstimationFormContext";
import { genderDropDown, AllianzLifeCarrierDetail } from "../../../../../constants";
import { healthDropdownOtpions } from "../../../Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";
import InfoIcon from "@material-ui/icons/Info";
import * as EstimationActions from "../../../../../actions/estimation";
import {
    asDbDateFormat,
    DATE_FORMAT_DOB_SAVE,
    isValidDate
} from "../../../../../utils/dateUtils";
import { useActions } from "../../../../../actions";
import TobaccoQuestions from "./TobaccoQuestions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { useFormikContext } from "formik";
import * as TrancheActions from "../../../../../actions/tranche";

const projectedIssueAge =
        <Typography variant="caption" className="f12 ml5">What is this?
            <Tooltip color="primary" title="If your birth date is within 6 months of
                        the issue date of the policy, the carrier
                        will increase the issue age by 1.
                        Example, if you are 39 today and your
                        birthday is a month after the issue date,
                        your issue age will be 40. This
                        calculation is being handled
                        automatically in the above field." arrow>
                <IconButton className="pl0" aria-label="Info">
                    <InfoIcon className="f18"/>
                </IconButton>
            </Tooltip>
        </Typography>;

export default function StepOne(props: { callingFrom: string, carrierId?: number }) {
    const { callingFrom, carrierId } = props;
    const trancheAction = useActions(TrancheActions);
    const { values, errors, touched, handleChange, setFieldValue }: any = useFormikContext();
    const estimationActions = useActions(EstimationActions);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    // const helperText = `Minimum ${maskCurrency(minCon + '')} contribution required`;

    React.useEffect(() => {
        getCurrentTranche().then((data) => setFieldValue("trancheNumber", data));
    }, []);

    const getCurrentTranche = async () => {
        const res = await trancheAction.trancheCurrent();
        return res && res.trancheNumber || null;
    };

    const renderFields = (rf: { setFieldValue: Function, setIssueAge: Function }) => {
        const { setFieldValue, setIssueAge } = rf;
        let fields: any [];
        const clientFirstName: any = {
            name: "firstName",
            label: <Label label={"Client First Name"} required={true}/>,
            isRequired: true,
            placeholder: "Client First Name",
            component: TextField,
            className: "",
            helperFieldText: "",
            disabled: false,

        };

        const clientLastName: any = {
            name: "lastName",
            label: <Label label={"Client Last Name"} required={true}/>,
            isRequired: true,
            placeholder: "Client Last Name",
            component: TextField,
            className: "",
            helperFieldText: "",
            disabled: false,

        };

        const dob: any = {
            name: "dob",
            label: <Label label={"Date of Birth"} required={true}/>,
            isRequired: true,
            placeholder: "Date of Birth",
            id: "dob",
            variant: "outlined",
            disableFuture: true,
            component: DatePicker,
            className: "fullWidthcustomFields",
            disabled: callingFrom === "ENROLLMENT",
            cb: async (val: any) => {
                if (isValidDate(val)) {
                    const dob: any = asDbDateFormat(val, DATE_FORMAT_DOB_SAVE);
                    const response: any = await estimationActions.getIssueAge(dob, values.carrierId);
                    const issueAgeVal: any = response && response.issueAge;
                    setFieldValue("issueAge", issueAgeVal);
                    setIssueAge(issueAgeVal);
                }
            }
        };

        const gender: any = {
            name: "gender",
            label: <Label label={"Select Gender"} required={true}/>,
            isRequired: false,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: genderDropDown,
            disabled: callingFrom === "ENROLLMENT",
        };

        const issueAgeField: any = {
            name: "issueAge",
            label: <Label label={"Projected Issue Age"}/>,
            placeholder: "Projected Issue Age",
            isRequired: false,
            component: TextField,
            className: "",
            helperFieldText: projectedIssueAge,
            disabled: true,
            InputLabelProps: {
                shrink: true,
            },
        };

        const trancheNumber: any = {
            name: "trancheNumber",
            label: <Label label={"Current Tranche"}/>,
            isRequired: false,
            component: TextField,
            className: "",
            variant: "outlined",
            disabled: true,
            InputLabelProps: {
                shrink: true,
            },
        };

        const healthType: any = {
            name: "healthType",
            label: <Label label={"Health"} required={true}/>,
            isRequired: false,
            filedType: "selectbox",
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            helperFieldText: carrierId === AllianzLifeCarrierDetail.value ? "Allianz is currently automatically upgrading to best class for clients who qualify." : "",
            options: healthDropdownOtpions,
        };

        if (callingFrom === "ESTIMATOR") {
            fields = [dob, gender, healthType];
            return fields;
        } else if (callingFrom === "CUSTOM_DESIGN") {
            fields = [clientFirstName, clientLastName, dob, gender, issueAgeField, healthType];
            return fields;
        }

        fields = [dob, gender, issueAgeField, trancheNumber, healthType];
        return fields;
    };

    return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="mt30 mb0 floatLeft w-100" variant="body1" component="strong">Basic Details</Typography>
                </Grid>
                <EstimationFormContext.Consumer>
                    {
                        (estimatorContextvalue: any) => {
                            const { issueAge, setIssueAge } = estimatorContextvalue;
                            const grpInfo: any = renderFields({ setFieldValue, setIssueAge });
                            return (
                                <>
                                    {grpInfo.map((field, index) => {
                                        return (
                                                <Grid item xs={12} sm={6}
                                                      md={field.name == "healthType" && callingFrom === "ESTIMATOR" ? 12 : 6}
                                                      key={index} className="mb15">
                                                    <TextFieldCommon
                                                            {...field}
                                                            values={values}
                                                            onChange={handleChange}
                                                    />
                                                    <span>
                                                        {field && field.helperFieldText}
                                                    </span>

                                                        <EstimationErrorCommon errors={errors} name={field.name}
                                                                               touched={touched}/>
                                                    </Grid>
                                            );
                                        })}
                                        {
                                                (callingFrom === "ENROLLMENT" || callingFrom === "INVITATION" || callingFrom === "CUSTOM_DESIGN" || callingFrom === "ESTIMATOR") &&
                                                <TobaccoQuestions callingFrom={callingFrom}/>
                                        }
                                        {
                                                // Commented ref NIWC-2517
                                                // (callingFrom === "ENROLLMENT" || callingFrom === "INVITATION" || callingFrom === "CUSTOM_DESIGN" || callingFrom === "ESTIMATOR") &&
                                                // <EmployerContributionQuestions />
                                        }
                                    </>
                            );

                        }
                    }
                </EstimationFormContext.Consumer>
            </Grid>

    );
}
