import React from "react";
import {Formik} from "formik";
import {Button, Grid, Typography} from "@material-ui/core";
import ProfileInformation from "../forms/ProfileInformation";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import {useActions} from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import moment from "moment";
import numeral from "numeral";
import { joinAsFullName } from "../../../../../utils/commonUtils";
import {getDateDiffInYear} from "../../../../../utils/dateUtils";
import { isAllianzApp } from "../../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

export const fullName = (user: {firstName: string, lastName: string}) => joinAsFullName(user);

const ClientProfileTab = (props: any) => {
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    let clientSigned = detail && detail.enrollment && detail.enrollment.clientSigned ? 1 : 0;
    const offerType = detail && detail.client && detail.client.underWritingOfferType || "";
    if(isAllianzApp(offerType)){
        clientSigned = detail && detail.enrollment && (detail.enrollment.enrollmentStatus + "." + detail.enrollment.enrollmentSubStatus) === "1.-1" ? 1 : 0;
    }
    const hasEnvelopeId = detail && detail.enrollment && detail.enrollment.envelopeId ? true : false;
    const agentCarriers = detail && detail.client && detail.client.agent && detail.client.agent.carriers;
    const accountActions = useActions(Actions);
    const {role, setActionItem, getClientDetail, clientDetail, ssnFlag} = props;

    const formVal = {
        firstName: detail && detail.firstName || "",
        middleName: detail && detail.middleName || "",
        lastName: detail && detail.lastName || "",
        cellPhone: detail && detail.phoneNumber || "",
        homePhone: detail && detail.client && detail.client.homePhone || "",
        phoneNumber: detail && detail.client && detail.client.workPhoneNumber || "",
        email: detail && detail.email || "",
        carrierName: detail && detail.client && detail.client.carrier && detail.client.carrier.carrierName,
        strategyName: detail && detail.client && detail.client.strategy && detail.client.strategy.displayName || "",
        gender: detail && detail.client && detail.client.gender || 0,
        age: detail && detail.client && getDateDiffInYear(detail.client.dob) || "",
        advisor: detail && detail.client && detail.client.agent && detail.client.agent.user && fullName(detail.client.agent.user) || "",
        imo: detail && detail.client && detail.client.imo && detail.client.imo.groupName || "",
        householdAnnualIncome: detail && detail.client && detail.client.householdAnnualIncome && Number(detail.client.householdAnnualIncome).toString() || "",
        householdNetWorth: (detail && detail.client && detail.client.householdNetWorth) || "",
        indIncomeLastYearTotal: (detail && detail.client && detail.client.indIncomeLastYearTotal) || "",
        netWorth: detail && detail.client && detail.client.netWorth || "",
        currentEmployer: detail && detail.client && detail.client.currentEmployer || "",
        currentNetWorth: detail && detail.client && detail.client.currentNetWorth || "",
        currentAnnualIncome: detail && detail.client && detail.client.currentAnnualIncome || "",
        occupation: detail && detail.client && detail.client.occupation || "",
        offerType: detail && detail.client && detail.client.underWritingOfferType || "",
        policyNumber: detail && detail.client && detail.client.policyNumber || "",
        tranceNo: detail && detail.client && detail.client.tranche && detail.client.tranche.trancheNumber || "N/A",
        traunchApplicationDeadline: detail && detail.client && detail.client.tranche && detail.client.tranche.deadlineDate ? moment(detail && detail.client.tranche && detail.client.tranche.deadlineDate).format("MM/DD/YYYY") : "N/A",
        trancheFundingDeadline: detail && detail.client && detail.client.tranche && detail.client.tranche.fundsDeadLineDate ?  moment(detail && detail.client.tranche && detail.client.tranche.fundsDeadLineDate).format("MM/DD/YYYY") : "N/A",
        projectFundingDeadline: detail && detail.client && detail.client.tranche && detail.client.tranche.projectFundingDate ? moment(detail && detail.client.tranche && detail.client.tranche.projectFundingDate).format("MM/DD/YYYY") : "N/A",
    };
    const [initialValues, setInitialValues] = React.useState(formVal);

    React.useEffect(()=>{
        if( detail && detail.client ){
            setInitialValues(formVal);
        }
    }, [detail && detail.client]);

    const validate = ((value: any) => {
        let errors: any = {};
        if(role === 1 || role === 2 || role === 4){
            if(value && value.phoneNumber) {
                let phone = value.phoneNumber.replace(/\D+/g, '');
                if(phone.length !== 10){
                    errors.phoneNumber = 'Invalid Phone Number';
                }
            }
            return errors;
        }
        if (!value.firstName) {
            errors.firstName = "Required";
        }
        if (!value.lastName) {
            errors.lastName = "Required";
        }
        if (!value.phoneNumber) {
            errors.phoneNumber = "Required";
        }
        if (!value.cellPhone) {
            errors.cellPhone = "Required";
        }
        if (!value.homePhone) {
            errors.homePhone = "Required";
        }
        if (!value.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
            errors.email = "Invalid email address";
        }
        if (!value.age) {
            errors.age = "Required";
        }
        if (!value.occupation) {
            errors.occupation = "Required";
        }
        if (!value.householdAnnualIncome) {
            errors.householdAnnualIncome = "Required";
        }
        if (!value.netWorth) {
            errors.netWorth = "Required";
        }
        return errors;
    });
    return (
            <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={values => validate(values)}
                    validateOnChange={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setInitialValues(values);
                            values["carrierId"] = detail && detail.client && detail.client.carrier && detail.client.carrier.id;
                            values["strategyId"] = detail && detail.client && detail.client.strategyId || 0;
                            values["homePhone"] = values["homePhone"] ? numeral(values["homePhone"]).value() : null;
                            values["indIncomeLastYearTotal"] = values["indIncomeLastYearTotal"] && numeral(values["indIncomeLastYearTotal"]).value() ? numeral(values["indIncomeLastYearTotal"]).value() : null;
                            values["householdNetWorth"] = values["householdNetWorth"] && numeral(values["householdNetWorth"]).value() ? numeral(values["householdNetWorth"]).value() : null;
                            values["netWorth"] = values["netWorth"] && numeral(values["netWorth"]).value() ? numeral(values["netWorth"]).value() : null;
                            values["currentNetWorth"] = values["currentNetWorth"] && numeral(values["currentNetWorth"]).value() ? numeral(values["currentNetWorth"]).value() : null;
                            values["currentAnnualIncome"] = values["currentAnnualIncome"] && numeral(values["currentAnnualIncome"]).value() ? numeral(values["currentAnnualIncome"]).value() : null;
                            await accountActions.updateUserBasicDetails(values, detail && detail.client && detail.client.userId || 0);
                            if (typeof setActionItem === 'function') {
                                setActionItem();
                            }
                        } catch (e) {
                            if (e && e.message == 422 && typeof getClientDetail === 'function') {
                                setTimeout(() => {
                                    getClientDetail((clientDetail && clientDetail.client && clientDetail.client.id || 0), ssnFlag)
                                }, 500);
                            }
                        }
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                        dirty
                  }) => (<form onSubmit={handleSubmit}>
                    <Typography variant="body1"><strong>Profile Information</strong></Typography>
                    <ProfileInformation clientSigned={clientSigned} values={values} handleChange={handleChange} {...initialValues} errors={errors}
                                        touched={touched} role={role} hasEnvelopeId={hasEnvelopeId}/>
                    { // for carrier role 7, do not show save button
                        role === 7 ? <></> : <Grid item xs={12}>
                        <div className="stickyFooter" id={"stickyFooter"} style={{
                            display: dirty ? "block" : "none",
                            transition: dirty ? "height 2s ease-in-out 2s" : "none",
                        }}>
                            <Typography className="mb0">Make sure to save your changes.
                                <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="ml20"
                                        size="large"
                                        disabled={isSubmitting || (clientSigned ? true : false)}
                                >
                                    Save Changes
                                </Button>
                            </Typography>
                        </div>
                    </Grid>
                    }
                </form>)}
            </Formik>
    );

};
export default ClientProfileTab;