import * as React from 'react';
import {FormikContextType, useFormikContext} from "formik";
import numeral from "numeral";

import {Grid, Typography} from "@material-ui/core";
import {ErrorCommon, NumberTextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Label} from "../../../../../components/formInputs";
import {isRequired} from "../../../../../utils/validation";


enum ECFieldsName {
    AnnualContribution = 'annualContribution',
    CombinedAnnualContribution = '_combinedAnnualContribution',
    EmployersAnnualContribution = '_employersAnnualContribution',
    TotalContribution = 'totalContribution',
}

interface ECProps {
    helperText: string;
}

export default ({helperText}: ECProps) => {
    const {
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values
    }: FormikContextType<any> = useFormikContext();

    const employerContributionFields = [
        {
            component: NumberTextField,
            fieldLabel: "Set your employer's annual contribution amount",
            id: ECFieldsName.EmployersAnnualContribution,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            label: <Label label={"Set your employer's annual contribution amount"} required={true}/>,
            name: ECFieldsName.EmployersAnnualContribution,
            variant: "outlined"
        },
        {
            component: NumberTextField,
            disabled: true,
            fieldLabel: "Combined Annual Contribution",
            helperText,
            id: ECFieldsName.CombinedAnnualContribution,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            label: <Label label={"Combined Annual Contribution"} required={true}/>,
            name: ECFieldsName.CombinedAnnualContribution,
            variant: "outlined",
        }
    ];


    React.useEffect(() => {
        // calc and update combined_annual_contribution
        const employersAnnualContribution = values[ECFieldsName.EmployersAnnualContribution];
        const yourContribution = values[ECFieldsName.AnnualContribution] || values[ECFieldsName.TotalContribution];
        const combinedAnnualContribution = numeral(yourContribution).value() + numeral(employersAnnualContribution).value();

        setFieldValue(ECFieldsName.EmployersAnnualContribution, employersAnnualContribution);
        setFieldValue(ECFieldsName.CombinedAnnualContribution, combinedAnnualContribution);
    }, [values[ECFieldsName.AnnualContribution], values[ECFieldsName.EmployersAnnualContribution], values[ECFieldsName.TotalContribution]]);

    return (
            <>
                {
                    employerContributionFields.map((f, index) => {
                        const {fieldLabel, ...field}: any = f;
                        return (
                                <Grid className="mb15" item key={index} xs={12}>
                                    <Typography className="mb20 mt15 floatLeft w100" component="strong" variant="body1">
                                        {fieldLabel}
                                    </Typography>
                                    <TextFieldCommon
                                            {...field}
                                            name={field.name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            validate={isRequired}
                                            values={values}
                                    />
                                    <ErrorCommon errors={errors} name={field.name} touched={touched}/>
                                </Grid>
                        );
                    })
                }
            </>
    );
};