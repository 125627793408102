import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import { FormLabel } from "@material-ui/core";
import { FieldProps } from "formik";
import Radio from "../formInputs/Radio";

type OptionProps = {
    label: string;
    value: string | number;
}

interface RadioProps extends FieldProps {
    name: string
    label: string
    value: string
    options: OptionProps[]
    disabled: boolean
}

const defaultOptions: OptionProps[] = [
    {
        label: "Yes",
        value: "Yes",
    }, {
        label: "No",
        value: "No",
    },
];
const FormikRadioGroup = ({
                              field,
                              form: { touched, errors },
                              value,
                              name,
                              label,
                              options = defaultOptions,
                                disabled,
                              ...props
                          }: RadioProps) => {
    const fieldName = name || field.name;
    return (
            <React.Fragment>
                {
                    label && <FormLabel className="mb0">{label}</FormLabel>
                }
                <div className="radioGroup">
                    <RadioGroup {...field} {...props} name={fieldName} >
                        {options.map((option: OptionProps, idx: number) => (
                                <Radio
                                        key={`${idx}-${fieldName}`}
                                        value={option.value}
                                        checked={(field.value  === option.value) ? true : false}
                                        label={option.label}
                                        id={`${idx}-${fieldName}`}
                                        disabled={disabled}
                                />
                        ))}
                    </RadioGroup>
                </div>

               {/* {touched[fieldName] && errors[fieldName] && (
                        <i>{errors[fieldName]}</i>
                )}*/}
            </React.Fragment>
    );
};

export default FormikRadioGroup;