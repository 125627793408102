import {EMAIL_VALIDATE_REGEX, REQUIRED_FIELD, ZIP_CODE_ALPHA_NUMERIC_CHAR, ZIP_CODE_CHAR} from '../constants';
import {unmaskCurrency, unmaskPhoneNumber} from "./commonUtils";
import numeral from "numeral";
import moment from "moment";

export const isEmpty = (value: any) => value === undefined || value === null || value === '';

const getFloatString = (num: string = "") => {
	num = (num.match(/\d/g) || []).join("");
	return num;
};

export const email = (value: string) => {
  if (!isEmpty(value) && !EMAIL_VALIDATE_REGEX.test(value)) {
    return 'Must be an email';
  }
};

/**
 * Simple value check tested with formik
 * @param v
 */
export const simpleRequired = (v: string) => {
    if (!v) {
        return REQUIRED_FIELD;
    }
};

export const required = (value: any) => {
  const valType = typeof value;
  if (valType === "object" && value && Object.keys(value).length <= 0 && (value instanceof Date === false)) {
    return `Required`;
  } else if (isEmpty(value) || /^\s+$/.test(value)) {
    return `Required`;
  }
};
export const validatePhoneNumber = (value: any) => {
    if(value) {
        const phone = unmaskPhoneNumber(value + "");
        if (!isEmpty(phone) && phone.length < 10) {
            return "Invalid phone number";
        }
    }
}
export const minLength = (min: number) => {
  return (value: any) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
};

export const maxLength = (max: number) => {
  return (value: any) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
};

export const minValue = (min: number) => {
  return (value: any) => {
    if (!isEmpty(value) && value < min) {
      return `Must be ${min} or greater`;
    }
  };
};

export const maxValue = (max: number) => {
  return (value: any) => {
    if (!isEmpty(value) && value > max) {
      return `Must be no more than ${max}`;
    }
  };
};

export const integer = (value: number) => {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
};

export const float = (value: any) => {
  if (isNaN(parseFloat(value))) {
    return 'Must be a float value';
  }
};

export const match = (field: any, type: string = '') => {
  return (value: any, data: any) => {
    if (!type && data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    } else if (type === 'RegExp') {
      return !field.test(value) && 'Invalid entry';
    }
  };
};

export const maxAnnualIncome = (max: number) => {
  return (value: any) => {
    const amount = Number(getFloatString(value + ""));
    if (!isEmpty(amount) && amount < max) {
      return `Minimum annual income must be ${max}`;
    }
  };
};

export const alphaNumeric = (value: string) => {
    return ZIP_CODE_ALPHA_NUMERIC_CHAR.test(value) ? false : "Zip code can only accept alphanumeric characters.";
}
export const validateNetWorthAllianzLife = (value: number, annnualIncome: number | undefined | string, setIsValidNetWorth: Function) =>{
    let minVal = 0;
    const income = Number(annnualIncome);
    if (!income) {
        setIsValidNetWorth(false);
        return "Please fill household income first";
    } else if (income >= 200000 && income < 400000) {
        minVal = (400000 - income) * 2.5;
        if( isEmpty(value) || parseFloat(unmaskCurrency(value.toString())) < minVal){
            setIsValidNetWorth(false);
            return "The minimum Net Worth for the indicated Income is " + numeral(minVal).format("$0,0");
        } else {
            return;
        }
    } else if (income >= 100000 && income < 200000) {
        minVal = 500000 + (200000 - income) * 2.5;
        if(isEmpty(value) || parseFloat(unmaskCurrency(value.toString())) < minVal) {
            setIsValidNetWorth(false);
            return "The minimum Net Worth for the indicated Income is " + numeral(minVal).format("$0,0");
        } else {
            return;
        }
    } else if (income >= 400000) {
        setIsValidNetWorth(false);
        return false;
    }
}

export const zipCodeValidation = (value: string) =>{
    if (!value) {
        return false;
    }
    return ZIP_CODE_CHAR.test(value) ? false : "Please enter a valid zip code";
}
export const validateDate = (value: any) => {
    const obj:any = moment(value);
    const SelectedDate = moment(obj.toDate()).format("MM/DD/YYYY");
    const date = moment(SelectedDate);
    if (isEmpty(value)) {
        return "Required";
    } else if(date.isValid() === false) {
        return "Invalid Date";
    }
};

export const isRequired = (value: any) => {
    let error:string = '';
    if (!value) {
        return `Required`;
    }
    return error;
};

/**
 * Phone number valid, has 10 digits, can't have less than that
 * @param val
 */
export const checkIncorrectPhoneNo = (val) => {
    const phoneNumberDigits = val ? val.replace(/\D+/g, '') : '';
    return phoneNumberDigits.length === 0 || phoneNumberDigits.length >= 10;
};

/**
 * Can't be in the future, default allows value to be null
 * @param val
 * @param allowNull
 */
export const noFutureDate = (val, allowNull = true) => {
    const isBeforeToday = (moment(val).isBefore());
    return allowNull ? ((!!val && isBeforeToday) || !val) : isBeforeToday;
};

/**
 * Date can't be that old, default allows value to be null
 * @param val
 * @param allowNull
 */
export const noAncientDate = (val, allowNull = true) => {
    const oldDate = '1799-12';
    const isAfter1800 = (moment(val).isAfter(oldDate));
    return allowNull ? ((!!val && isAfter1800) || !val) : isAfter1800;
};
