import * as React from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import {
    asDbDateFormat, DATE_FORMAT_DOB_SAVE,
    DATE_FORMAT_SERVICE_DASHBOARD
} from "../../utils/dateUtils";

const handleDateChange = (date: any, setFieldValue: Function, name: string, cb: Function, dateFormat: string) => {
    const newDate = !date ? null : asDbDateFormat(date, dateFormat);
    setFieldValue(name, newDate);
    if(typeof cb === "function")
        cb(newDate);
};

interface DatePickerProp {
    field: { value: string, name: string },
    form: { setFieldValue: Function, setFieldTouched?: Function },
    className? : string,
    InputLabelProps? : any,
    disabled? : boolean,
    label: string,
    minDate?: Date,
    cb: Function,
    disableFuture?:boolean,
    errCb?: Function,
    disablePast?: boolean,
    saveFormat?: string
}
const DatePicker = (props: DatePickerProp) => {
    const { field: { value, name }, disabled, minDate, disableFuture, disablePast, form: { setFieldValue, setFieldTouched }, label, cb, className, InputLabelProps, errCb, saveFormat } = props;
    const [emptyLabel, setEmptyLabel] = React.useState("Select Date");
    const defaultDate = !value ? null : asDbDateFormat(value, DATE_FORMAT_SERVICE_DASHBOARD);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                name={name}
                id="date-picker-dialog"
                label={label}
                className={className}
                format="MM/dd/yyyy"
                value={defaultDate}
                inputVariant="outlined"
                disabled={disabled || false}
                onChange={(date) => {
                    let dateFormat = saveFormat === "USER" ? DATE_FORMAT_SERVICE_DASHBOARD : DATE_FORMAT_DOB_SAVE;
                    handleDateChange(date, setFieldValue, name, cb, dateFormat);
                    if(typeof setFieldTouched === 'function') {
                        setFieldTouched(name, true, false)
                    }
                }}
                onClick={(e) => {
                    const event: any = Object.assign({}, e);
                    if (emptyLabel === "Select Date" && !value) {
                        setTimeout(() => {
                            event.target.selectionStart = 0;
                            event.target.selectionEnd = 0;
                        }, 100);
                    }
                    setEmptyLabel("__/__/____");
                }}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                InputLabelProps={InputLabelProps}
                minDate={minDate}
                emptyLabel={emptyLabel}
                disableFuture={disableFuture || false}
                disablePast={disablePast || false}
                onError = {(err:any, val:any) => {
                    if(typeof errCb === 'function') {
                        errCb(err);
                    }
                }}
                inputProps={{ inputMode: "numeric" }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;