export interface Alert {
    message: string,
    severity?: string,
    statusCode: number,
    error?: string,
}

export interface Docusign {
    evvelopeUrl: string
}

export interface PasswordPayload {
    password?: string,
    confirmPassword?: string,
}

export interface SetPassword {
    inviteToken: string,
    password: string,
    confirmPassword: string,
}

export interface AccountPassword {
    currentPassword: string,
    password: string,
    confirmPassword: string,
}

export interface PagingParams {
    page: number,
    rowsPerPage: number,
    searchText?: string,
    filter?: number,
    sortBy?: string,
    order?: string,
    trancheNo?: number
}

type Accounts = { [index: string]: string }

export const AccountType: Accounts = {
    0: "Anonymous",
    1: "MasterAdmin",
    2: "Admin",
    3: "IMOs",
    4: "Agents",
    5: "Individuals",
    6: "Multi-Life",
    7: "Carrier",
    8: "Groups",
    10: "Group-Links",
    12: "Trustees",
    13: "Banks",
    14: "Client-Lists",
    15: "IMO Accounts",
    16: "Participants",
    18: "Aggregator"
};

export enum AccountStatus {
    Disabled = -2,
    Bounced = -1,
    Invited,
    Opened,
    CreatedAccount,
    LoggedIn,
    EnrollmentStarted,
    EnrollmentCompleted,
    DocumentsPending,
    DocumentsCompleted,
    NotQualified,
    Qualified,
    EnrollmentRequested,
    CompletedOnlineQuestionnaire,
    CompletedApplication,
    AgentSigned,
    FinalIllustrationAccepted,
    CompletedBasicInfo,
    ApplicationSubmittedToCarrier,
    ProposalSubmittedToAdvisor,
    ProposalApprovedByAdvisor,
    ProposalSentToClient,
    ProposalSignedByClient,
    FundingReceived,
    TrusteeSignAndEnrollmentClosed,
    Deceased,
    Surrendered,
    InForce,
    ApplicationReceivedByCarrier,
    FinalDocSentToCarrier,
    InForceOverride
}

export const AccountStatusArr: {
    [index: number]: string
} = {
    "-3": "Invited approved imo",
    "-2": "Disabled",
    "-1": "Bounced Email",
    0: "Delivered",
    1: "Opened Invite",
    2: "Created Account",
    3: "Logged In",
    4: "Started Enrollment",
    5: "Enrollment Completed",
    6: "Documents Pending",
    7: "Documents Completed",
    8: "Not Qualified",
    9: "Qualified",
    10: "Enrollment Requested",
    11: "Questionnaire Completed",
    12: "Client Application Signed",
    13: "Agent Report Signed",
    15: "Basic Info Completed",
    16: "Application Submitted To Carrier",
    17: "Proposal Submitted To Agent",
    18: "Proposal Approved By Agent",
    19: "Proposal Sent To Client",
    20: "Proposal Approved / Signed By Client",
    21: "Client Payment Received",
    22: "Trustee Signed",
    23: "Deceased",
    24: "Surrendered",
    25: "Policy Issued",
    26: "Application Received By Carrier",
    27: "Final Docs Sent To Carrier",
    99: "Currently Enrolling" //Just Filter Purpose, Not saving into DB
}

export enum ActionType {
    ALERT,
    CLIENT_IP,

    ADD_ACCOUNT,
    DELETE_ACCOUNT,
    UPDATE_ACCOUNT,
    LIST_ACCOUNTS,
    LIST_CARRIERS_STRATEGY,
    AGENT_CLIENT_LIST,
    IMO_AGENTS_LIST,
    CHANGE_PASSOWRD,
    GET_ACCOUNT,
    LOADING_ACCOUNTS,

    ADMIN_ACTIONS_ONBOARDING,

    GET_PROFILE_DETAILS,
    ADD_AGENT_CARRIER,
    UPDATE_AGENT_CARRIER,
    UPDATE_CARRIER_REQUEST,
    DELETE_AGENT_CARRIER,
    GET_DESIGNATION_LIST,
    GET_ADDITIONAL_CARRIER,
    GET_ALL_CARRIER,
    GET_ALL_STRATEGY,
    GET_STRATEGY_BY_ID,
    CARRIER_REQUEST,

    VERIFY_INVITATION,
    SET_PASSWORD,
    SIGNED_URL,

    USER_DETAILS,
    LOGIN,
    LOGOUT,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VERIFY_RESET_PASSWORD_LINK,
    DEMO_CLIENT,
    EXIT_DEMO_CLIENT,

    ADD_CLIENT,
    GET_CLIENT,
    FIND_CLIENT_BY_EMAIL,

    ADD_CARRIER,
    DELETE_CARRIER,
    UPDATE_CARRIER,
    LIST_CARRIERS,

    GET_ESTIMATION,
    LIST_IMOS,

    GET_ENVELOPE,
    BUILD_ENVELOPE,
    DOWNLOAD_ENVELOPE,

    CREATE_ENROLLMENT,

    SELLER_AGREEMENT,

    GET_ALL_TRUSTEES,
    GET_ALL_BANKS,

    ADD_TRANCHE,
    LIST_TRANCHES,
    UPDATE_ENROLLMENT,
    GET_ENROLLMENT,
    IDENTITY_VERIFICATION,
    UPDATE_ENROLLMENT_ACTION_ITEM,

    GET_CARRIER_PRODUCTS_BY_ID,
    GET_PRODUCT_BY_ID,

    GET_CARRIER_OFFERTYPE_BY_ID,
    GET_OFFERTYPE_BY_ID,
    GET_PRE_ESTIMATION,
    GET_ADMIN_ACTIVITY_LOGS,
    GET_COMPANY_INVITE_DATA,
    ProfileImage,
    REFRESH_APPLICATION,
    LEFTPANEL,
    DOC_FOR_REQUEST,
    LEADSTATS,
    SET_AGENT_GROUP,
    FETCH_TRAINING,
    FETCH_WATCHING_TRAINING_VIDEO,
    SAVE_TRAINING,
    SAVE_WATCHING_TRAINING_VIDEO,
    UPDATE_TRAINING,
    REMOVE_TRAINING,
    FLUSH_TRAINING,
    GET_CLIENT_CASE_DETAILS,
    IS_REFRESH,

    ADD_INVITED_CLIENT_DETAIL,
    LOAD_AGENT_DOCS,
    FLUSH_AGENT_DOCS,

    APPROVED_STATS,
    FLUSH_APPROVED_STATS,

    APPROVED_LIST,
    FLUSH_APPROVED_LIST,

    APPROVED_IMO_LIST,
    FLUSH_APPROVED_IMO_LIST,

    APPROVED_DETAILS,
    FLUSH_APPROVED_DETAILS,

    ADD_DIVISION,

    LIST_DIVISIONS,

    GET_AGGREGATORS,
    FLUSH_AGGREGATORS,
    GET_AGGREGATOR_IMO_LIST,
    ADD_STRESS_UUID,
    ADD_REPORT_UUID,

    EXECUTION_ARN,

    ADD_MINIMUM_CONTRIBUTION,
    QUICK_ESTIMATION,
    APPLICATION_GET_FORM,
    APPLICATION_ENROLLMENT_START,
    APPLICATION_ENROLLMENT_SUBMIT,
    APPLICATION_ENROLLMENT_GET_ALL,
    APPLICATION_ENROLLMENT_GET_QUESTION_BY_ID,
    APPLICATION_ENROLLMENT_GET_NEXT_QUESTION,
    APPLICATION_ENROLLMENT_SEARCH_OPTIONS,
    APPLICATION_ENROLLMENT_SAVE_ANSWER,
    APPLICATION_ENROLLMENT_SAVE_PHYSICIAN,
    APPLICATION_ENROLLMENT_SYNC,
    APPLICATION_ENROLLMENT_RESET,
    APPLICATION_PROPOSAL_RESET,
    GET_HIPAA_ID,
    HIPAA_AGREEMENT,
    APPLICATION_ENROLLMENT_FETCH_PHYSICIAN,
}

export interface Action<T> {
    type: ActionType;
    payload: T;
}

export interface FormInputProps {
    required?: boolean,
    disabled?: boolean,
    fullWidth?: boolean,
    label?: string,
    emailError?: boolean,
    InputProps?: {
        inputProps?: {
            min?: number
        }
    },
    meta: {
        touched: boolean
        invalid?: boolean
        error: boolean
    },
    options?: any,
    input: any,
    variant?: any
}

export interface Aws {
    dirName: string;
    objectName?: string;
    contentType?: string;
}

export interface CleanResponse {
    data: any;
    message: string;
}

export type CsvField ={
    label: string,
    value: string | ((row: any, field: any) => any)
}