import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import clientSnapshot from "../images/client_snapshot.svg";
import {IconButton, Link, List, Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../../../reducers";
import {dateUtils} from "../../../../../src/utils";
import {AccountStatusArr} from "../../../../model";
import numeral from "numeral";
import {isMobile} from "react-device-detect";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import {TooltipProps} from "@material-ui/core/Tooltip";
import {getGroupNumber} from "../../../../utils/commonUtils";
import {clientSource, GWT_GEN2_STRATEGY, PolicyStatusEnum} from "../../../../constants";
import EditIcon from "@material-ui/icons/Edit";
import TrancheNumberPopUp from "./TrancheNumberPopUp";
import {Link as RouterLink} from "react-router-dom";
import ViewServicing from "../../../../components/client/ViewServicing";
import {fullName} from "./tabs/ClientProfileTab";
import {usePolicyContext} from "../../../../components/clientServicing/Payments/PolicyContext";
import LockIcon from "@material-ui/icons/Lock";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

const ClientSnapshot = (props: { mobileContent?: boolean, refreshDetails?: Function, caseDetailButtonRef?: any }) => {
    const {mobileContent = false, caseDetailButtonRef} = props;
    const [trancheSelectBox, setTrancheSelectBox] = React.useState(false);
    const detail: any = useSelector((state: RootState) => state.clientAccount);
    const user: any = useSelector((state: RootState) => state.auth.user);
    let lastLogin: any = detail && detail.lastLogin;
    let currentStatus: any = (detail && detail.inviteStatus) || 0;
    let currentPolicyStatus: any = detail && detail.client && detail.client.currentPolicyStatus || "";
    let advisor: any = detail && detail.client && detail.client.agent && detail.client.agent.user;

    let clientListName = (detail && detail.client && detail.client.clientlist && detail.client.clientlist.clientListName) || '';
    let companyName = (detail && detail.client && detail.client.company && detail.client.company.companyName) || '';
    let trancheNo = (detail && detail.client && detail.client.tranche && detail.client.tranche.trancheNumber) || "";
    const clientDetail: any = (detail && detail.client) || undefined;
    const source: string = clientDetail && clientDetail.source;
    let thisYearContributionAmountDue = (detail && detail.client && detail.client.issuedContribution) || "";
    let contributionAmount = (detail && detail.client && detail.client.contributionAmount) || "";
    contributionAmount = thisYearContributionAmountDue || contributionAmount;
    let strategyName = (detail && detail.client && detail.client.strategy && detail.client.strategy.displayName) || "Kai-Zen";
    const isGWTGen2 = (detail && detail.client && detail.client.strategyId === GWT_GEN2_STRATEGY)
    const { policy }: any = usePolicyContext();
    const openTrancheSelect = () => {
        if (caseDetailButtonRef && caseDetailButtonRef.current) {
            caseDetailButtonRef.current.click();
        }
        setTrancheSelectBox(true);
    }
    const policyRow = policy && policy.clientPayments && policy.clientPayments.filter(payment => !!(payment.amount)).length > 0;
    return (

                <>
                {
                    isMobile && !mobileContent ? "" :
                            <>
                                {
                                        (user.role === 1 || user.role === 2) &&
                                        <TrancheNumberPopUp trancheSelectBox={trancheSelectBox}
                                                            setTrancheSelectBox={setTrancheSelectBox}
                                                            clientDetail={detail}
                                                            refreshDetails={props.refreshDetails}
                                        />

                                }

                                <CardSnapshot title="Client Snapshot">
                                    <CardContent className="floatLeft w100">
                                        <List className="ClientDetailsList">
                                            <li><label>Last Logged In</label> <span
                                                    className="labelValue"> {lastLogin ? dateUtils.asDbDateFormat(lastLogin) : 'NA'} </span>
                                            </li>
                                            <li>
                                                <label>Current Status</label> <span
                                                    className="labelValue colorBlue"> {currentPolicyStatus === PolicyStatusEnum.InForce ?
                                                    <ViewServicing
                                                            clientName={detail.firstName + " " + detail.lastName}
                                                            clientId={detail.client.id}
                                                            stratergyId={detail.client.strategyId}
                                                            carrierId={detail.client.carrierId}
                                                            btnText={"Policy Issued - View Servicing"} /> : (currentStatus === 22 ? PolicyStatusEnum.InForce : AccountStatusArr[currentStatus])}
                                            </span>
                                            </li>
                                            {detail && detail.role === 6 &&
                                                    <li><label>Group Name </label> <span
                                                            className="labelValue">{companyName}</span>
                                                    </li>
                                            }
                                            {detail && detail.role === 5 && detail.client && detail.client.clientListId &&
                                                    <li><label>Client List </label> <span
                                                            className="labelValue colorBlue">{clientListName}</span>
                                                    </li>
                                            }

                                            <li>
                                                <label>Agent</label>
                                                {
                                                    <span className="labelValue">
                                                    {
                                                            advisor &&
                                                            (
                                                                    user && (user.role <= 3 || user.role === 15) ?
                                                                            <Link
                                                                                    component={RouterLink}
                                                                                    to={'/new-agent/detail/' + advisor.id}
                                                                            >
                                                                                {advisor.firstName + " " + advisor.lastName}
                                                                            </Link> :
                                                                            advisor.firstName + " " + advisor.lastName
                                                            )
                                                    }
                                                </span>
                                                }
                                            </li>
                                            {contributionAmount && !isGWTGen2 && <li><label>Annual Client Contribution</label> <span
                                                    className="labelValue">{numeral(contributionAmount).format("$0,0")}</span>
                                            </li>}
                                            {source && <li><label>Source</label> <span
                                                    className="labelValue">{clientSource[source]}</span>
                                            </li>}
                                            {/*{employer && <li><label>Employer</label> <span className="labelValue">{employer}</span></li> }*/}
                                            {/*{employerContribution && <li><label>Employer Contribution</label> <span className="labelValue">${employerContribution}</span></li> }*/}

                                            <li>
                                                <label>Tranche Number</label> {
                                                    (user.role === 1 || user.role === 2) && !(policyRow) &&
                                                    <EditIcon className="f16 floatRight cursorPointer iconhover"
                                                              onClick={() => openTrancheSelect()} />
                                            }

                                                {
                                                        (user.role === 1 || user.role === 2) && (policyRow) &&
                                                        <LockIcon className="f16 floatRight textBlackLight" style={{marginTop:3}}/>
                                            } <span className="labelValue">{trancheNo > 0 ? trancheNo : 'NA'}</span>

                                            </li>

                                            <li><label>{`Payment Deadline (Current Tranche ${trancheNo})`}:</label>
                                                <span className="labelValue valueHeighlight">
                                {clientDetail && clientDetail.tranche && clientDetail.tranche.trancheNumber &&
                                        moment(clientDetail && clientDetail.tranche && clientDetail.tranche.fundsDeadLineDate).format("MM/DD/YYYY")
                                }
                                                    {clientDetail && clientDetail.tranche && clientDetail.tranche.trancheNumber &&
                                                            <BootstrapTooltip
                                                                    color="primary" className="floatRight pd0"
                                                                    title={`This is the date your first year contribution is due to ${strategyName}`}
                                                            >
                                                                <IconButton className="f16 floatRight"
                                                                            aria-label="delete">
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </BootstrapTooltip>
                                                    }
                            </span>
                                            </li>
                                            {clientDetail && clientDetail.tranche && clientDetail.tranche.trancheNumber && !isGWTGen2 &&
                                                    <li><label>Projected Funding Date</label> <span
                                                            className="labelValue valueHeighlight">
                            {moment(clientDetail && clientDetail.tranche && clientDetail.tranche.projectFundingDate).format("MM/DD/YYYY")}
                                                        <BootstrapTooltip
                                                                color="primary" className="floatRight pd0"
                                                                title="This is the date we project the lender will fund the full
                                         premium to your policy to put your plan inforce.">
                                                <IconButton className="f16 floatRight" aria-label="delete">
                                                    <InfoIcon/>
                                                </IconButton>
                                            </BootstrapTooltip></span>
                                                    </li>
                                            }

                                            {clientDetail && clientDetail.tranche && clientDetail.tranche.trancheNumber && !isGWTGen2 &&
                                                    <li>
                                                        <label>Group Number </label>
                                                        <span className="labelValue">
                                                {getGroupNumber(`${clientDetail.tranche.trancheNumber}`)}
                                            </span>
                                                    </li>
                                            }
                                            {
                                                    clientDetail && clientDetail.agent &&
                                                    <li><label>Agent Type</label>
                                                        <span className="labelValue">{clientDetail.agent.agentType === 1 ? "Preferred" : 'Approved'}</span>
                                                    </li>
                                            }

                                            {isGWTGen2 &&
                                                    <li><label>Product Type</label>
                                                        <span className="labelValue">{strategyName}</span>
                                                    </li>
                                            }

                                            {isGWTGen2 && clientDetail.gwtClients && clientDetail.gwtClients.firstName &&
                                                    <li><label>Gen 1 Name</label>
                                                        <span className="labelValue"> {fullName(clientDetail.gwtClients)} </span>
                                                    </li>
                                            }

                                        </List>
                                    </CardContent>
                                </CardSnapshot>

                            </>
                }
            </>
    );
};

export const CardSnapshot = ({title, children}) => (
        <CardWithImage src={clientSnapshot} title={title}>
            {children}
        </CardWithImage>
)

export const CardWithImage = ({src, title, children}) => (
        <Card className="customCard boxShadow w100 mb20">
            <CardHeader
                    className="pb0"
                    avatar={
                        <img src={src} alt="#"/>
                    }
                    title={<React.Fragment>
                        <Typography
                                component="span"
                                variant="body1"
                        >
                            <strong>{title}</strong>
                        </Typography>
                    </React.Fragment>}
            />
            {children}
        </Card>
)
export default ClientSnapshot;