import React from 'react';
import {useSelector} from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import numeral from "numeral";
import * as yup from "yup";
import {v4 as uuidv4} from 'uuid';
import {FormikValues, FormikWrapper, NumberTextField, SelectField} from "../../formikFormInputs";
import {useActions} from "../../../actions";
import * as Actions from "../../../actions/estimation";
import * as AccountActions from "../../../actions/account";
import * as CarrierActions from "../../../actions/carrier";
import * as EstimatorActions from "../../../actions/estimator";
import * as ClientAction from "../../../actions/client";
import {
    contributionOptions,
    createEstimateReports,
    incomeOptions,
    isFormValid,
    MONTHLY,
    ProposalCommonProps,
    ProposalEnum,
    rateClassMappingObject,
    UCORIConstantKeyProps
} from "./helper";
import {ProposalForm, UploadedDocumentTable} from "./index";
import Socket from "../../../pages/client/Estimation/V1/Socket";
import TestingProcessDialog from "../Estimation/TestingProcessDialog";
import {getMinimumDesireLifeTimeBenefits, webHookURL} from "../../../pages/client/Estimation/V1/Steppers";
import {ContributionAmount as contributionPayload} from "../../../pages/client/Estimation/V1/ContributionAmount";
import {DeathBenefit as deathBenefitPayload} from "../../../pages/client/Estimation/V1/DeathBenefit";
import {setOptions} from "../../../pages/client/Estimation/V1/Components/StepThreeTwo";
import {Label} from "../../formInputs";
import {RootState} from "../../../reducers";
import {getCarrierCode, joinAsFullName, maskCurrency, noop, unmaskCurrency} from "../../../utils/commonUtils";
import { ATLANTIC_HEALTH, CallingFromObject, cashStreamSlug, NationalLifeCarrierDetail } from "../../../constants";
import { ANNUALLY } from '../../../pages/client/Estimation/V1/Components/ContributionFrequency';
import { useCreateAllocationPayLoad } from "../../../pages/client/Estimation/Hooks/Hooks";
import {
    addPlus,
    ALZInterestPayload, calculateGrowthRate, InvestmentValues,
    isALGCarrier,
    stressAllocationForALZ,
    stressAllocationsData,
} from "../../../pages/client/Estimation/utils/utils";


type UHS = {
    callingFrom: string,
    clientId: number,
    functionCallOnUUid: Function,
    functionOnDataLoadingEnable: Function,
    issueAge: number | null,
}

const frequencyMappingObject = {
    'annual': ANNUALLY,
    'monthly': MONTHLY,
}

interface ContentProps extends ProposalCommonProps {
    fileName: string;
    setFileName: Function;
    setHasReports: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#000",
        },
    }),
);

const getContributionAmount = (contributionAmmount: number, paymentFrequency: (typeof ANNUALLY | typeof MONTHLY)): number => {
    if (paymentFrequency === ANNUALLY) {
        return Number(unmaskCurrency(contributionAmmount.toString()));
    } else {
        return Number(unmaskCurrency(contributionAmmount.toString())) * 12;
    }
}

export const useFields = ({client, issueAge}) => {
    const carrierId: number = client && client.carrier && client.strategy ? client.carrier.id : NationalLifeCarrierDetail.value;
    const ageIncrement: number = NationalLifeCarrierDetail.id === carrierId ? 15 : 16; // 15 in case of NL and 16 in case of AL - NIWC-2721
    const carrierActions = useActions(CarrierActions);
    const userDetail = useSelector((root: RootState) => root.clientAccount);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const {getLivingBenefitsList} = useActions(AccountActions);
    let atlantic = (ATLANTIC_HEALTH.includes(userDetail && userDetail.client && userDetail.client.company && userDetail.client.company.companyLinkUrl as any) && userDetail.role === 6)
    const [livingBenefits, setLivingBenefits] = React.useState<object[]>([]);
    const [actualHealthRatingOption, setActualHealthRatingOption] = React.useState([]);
    const [beginDistributions, setBeginDistributions] = React.useState(setOptions(65));
    const [endDistributions, setEndDistributions] = React.useState<any[]>(setOptions(65));

    React.useEffect(() => {
        livingBenefitsDropDown().then();
    }, []);
    React.useEffect(() => {
        if (!!issueAge) {
            const beginDistVal: number = issueAge + ageIncrement;
            const res: any[] = setOptions(beginDistVal);
            setBeginDistributions(res);
            setEndDistributions(res);
        }

    }, [issueAge]);
    React.useEffect(() => {
        if (client.carrierId) {
            carrierActions.getCarrierActualHealthTitle(client.carrierId).then((res) => {
                const actualHealthArr = res && res.length && res.map((item) => {
                    return {
                        key: item.title,
                        value: item.title,
                        name: item.title,
                    };
                }) || [];
                setActualHealthRatingOption(actualHealthArr);
            });
        }
    }, []);

    const livingBenefitsDropDown = async () => {
        let livingBenefitsArr: object[] = [];

        const result = await getLivingBenefitsList();
        if (result && result.length > 0) {
            result.forEach((item: any) => {

                const pushTo: object = {
                    key: item.id,
                    value: item.id,
                    name: item.name,
                };
                livingBenefitsArr.push(pushTo);
            });
        }
        setLivingBenefits(livingBenefitsArr);
    };

    const UploadProposalFields = () => {
        const fields: any = [
            {
                className: "mt3",
                component: NumberTextField,
                id: "tranche",
                label: <Label label="Tranche No" required/>,
                name: "tranche",
                onChange: noop,
                size: 6,
            },
            {
                className: "mt3",
                component: SelectField,
                id: "livingBenefit",
                isRequired: true,
                label: <Label label={"Living Benefits"} />,
                name: "livingBenefit",
                onSelectChange: noop,
                options: livingBenefits,
                multiple: true,
                required: true,
                // value:[],
                size: 6,
                variant: "outlined",
            },
            {
                className: "mt3",
                component: SelectField,
                id: "healthRating",
                isRequired: true,
                label: <Label label={"Actual Health Rating"} required/>,
                name: "healthRating",
                onSelectChange: noop,
                options: actualHealthRatingOption,
                required: true,
                size: 6,
                variant: "outlined",
            },
        ];
        return fields;
    }

    const contributionTypeConditionalFields = (values: any) => {
        const fields: any = [];
        if (values.contributionType == contributionOptions.contribution.value) {
            fields.push({
                className: "mt3",
                component: NumberTextField,
                id: "deathBenefitAmount",
                label: <Label label="Death Benefit Amount" required/>,
                name: "deathBenefitAmount",
                onChange: noop,
                autoComplete:"off",
                size: 6,
            })
        }
        if (values.contributionType == contributionOptions.deathBenefits.value) {
            fields.push({
                className: "mt3",
                component: NumberTextField,
                id: "contributionAmount",
                label: <Label label="Contribution Amount" required/>,
                name: "contributionAmount",
                onChange: noop,
                autoComplete:"off",
                size: 6,
                helperText: atlantic ? `` : `Minimum ${maskCurrency(minCon + "")} contribution required`
            })
        }
        return fields;
    }

    const incomeConditionalFields = (values: any) => {
        const fields: any = [];
        if (values.incomeOptions == incomeOptions.income.value) {
            fields.push({
                id: "incomeStartAge",
                component: SelectField,
                label: <Label label="Income Start Age" required/>,
                name: "incomeStartAge",
                size: 6,
                className: "mt3",
                options: beginDistributions,

            });
            fields.push({
                id: "incomeEndAge",
                component: SelectField,
                label: <Label label="Income End Age" required/>,
                name: "incomeEndAge",
                size: 6,
                className: "mt3",
                required: true,
                options: endDistributions,
            })
        }
        return fields;
    }

    return {UploadProposalFields, contributionTypeConditionalFields, incomeConditionalFields};
};

const useHandleSubmit = ({clientId, functionCallOnUUid, functionOnDataLoadingEnable, callingFrom, issueAge}: UHS) => {
    const estimationActions = useActions(Actions);
    const estimatorActions = useActions(EstimatorActions);
    const {getClient} = useActions(ClientAction);
    const loggedInUser = useSelector((root: RootState) => root.auth.user);
    const userDetail = useSelector((root: RootState) => root.clientAccount);
    const createAllocationPayLoad = useCreateAllocationPayLoad("")

    const firstRun = () => {
        (
            async () => {
                await getClient(clientId)
            }
        )();
    };

    React.useEffect(firstRun, []);

    const handleRunIllustration = async (props: FormikValues) => {
        if (!userDetail) {
            return;
        }

        const {client, role} = userDetail;

        if (!client) {
            return;
        }

        const {carrierId = 1, strategyId = 1, userId} = client;
        const clientFullName: string = joinAsFullName(userDetail);
        let agentFullName: string = '';
        if (client.agent && client.agent.user) {
            agentFullName = joinAsFullName(client.agent.user);
        }

        const carrierCode = getCarrierCode(carrierId);
        const uuid = uuidv4();
        const webHookUrl: string = `${webHookURL}/${userId}/${loggedInUser.id}?callingFrom=${callingFrom}`;

        const {values}: any = props;

        /*
        when we have death benefit, send 0 for contributionamount, and run.solvefor = Contribution_Amount
        When we have contribution amount, send 1000000 for totaldeathbenefit and run.solvefor = Death_Benefit
        */

        functionOnDataLoadingEnable(true);
        functionCallOnUUid(uuid);

        const estimatorData = await estimatorActions.getEstimatorDetails({carrierId, strategyId});

        const rateClass: string = values.healthRating as string;

        let payload: any = {};

        if (values && values.incomeOptions === incomeOptions.deathBenefitsOnly.value) {
            if (values && values.contributionType === contributionOptions.contribution.value) {
                payload = {...contributionPayload};
                payload["common"]["contributionamount"] = 0; // todo userdetails
                payload["common"]["totaldeathbenefit"] = numeral(values && values.deathBenefitAmount).value();
            } else if (values && values.contributionType === contributionOptions.deathBenefits.value) {
                payload = {...deathBenefitPayload};
                payload['run']['deathbenefitonly'] = true;
                payload["common"]["totaldeathbenefit"] = 1000000;
                if(!!values.totalCombinedContribution){
                    payload["common"]["contributionamount"]  = values.totalCombinedContribution
                }else{
                    payload["common"]["contributionamount"] = getContributionAmount(numeral(values && values.contributionAmount).value(), values.contributionFrequency) ;
                }
            }
        }

        if (values && values.incomeOptions === incomeOptions.income.value) {
            payload = {...deathBenefitPayload};
            payload['run']['deathbenefitonly'] = false;
            payload["common"]["incomeagefrom"] = numeral(values && values.incomeStartAge).value();
            payload["common"]["incomeageto"] = numeral(values && values.incomeEndAge).value();
            payload["common"]["totaldeathbenefit"] = 1000000;
            if(!!values.totalCombinedContribution){
                payload["common"]["contributionamount"]  = values.totalCombinedContribution
            }else {
                payload["common"]["contributionamount"] = getContributionAmount(numeral(values && values.contributionAmount).value(), values.contributionFrequency);
            }
            payload["common"]["contributionamountemployer"] = !!values.employerContribution ? numeral(values.employerContribution).value() : 0;
        }

        payload["id"] = uuid;
        payload["webhook"] = webHookUrl;
        payload["InvestmentType"] = addPlus(values["plus"], values["InvestmentType"]);
        payload["common"]["fullname"] = clientFullName;
        payload["common"]["gender"] = (userDetail && userDetail.client && userDetail.client.gender === 1) ? "Male" : "Female";
        payload["common"]["agent"]["fullname"] = agentFullName;
        payload["common"]["issueage"] = issueAge || client.issueAge;
        payload["common"]["rateclass"] = rateClassMappingObject[rateClass] ? rateClassMappingObject[rateClass] : rateClassMappingObject['default']; // Possible Values 'Elite_NT' | 'Preferred_NT' | 'Select_NT' | 'Standard_NT' | 'Express_Standard_NT1' | 'Express_Standard_NT2' | 'Preferred_Tobacco' | 'Standard_Tobacco' | 'Express_Standard_Tobacco';
        payload["common"]["dateofbirth"] = moment(userDetail && userDetail.client && userDetail.client.dob).format("MM/DD/YYYY");
        /* Update estimator data on payload. Note: Estimator Data is provided by db */

        if (estimatorData) {
            let commonGrowthRate = estimatorData["growthRate"] || payload["common"]["growthrate"];
            let growthRate = (commonGrowthRate * 100).toFixed(2);
            payload["stress"]["loanassumptions"]["assumptions"] = estimatorData["stressLoanRates"].split(",") || payload["stress"]["loanassumptions"]["assumptions"];
            payload["illustration"]["loanassumptions"]["assumptions"] = estimatorData["illustrationLoanRates"].split(",") || payload["illustration"]["loanassumptions"]["assumptions"];
            payload["common"]["growthrate"] = commonGrowthRate;
            payload["common"]["capitalgainstaxrate"] = estimatorData["capitalGainRate"] || payload["common"]["capitalgainstaxrate"];
            payload["common"]["managementfee"] = estimatorData["managementFee"] || payload["common"]["managementfee"];
            payload["common"]["statetaxrate"] = estimatorData["averageStateIncomeTaxRate"] || payload["common"]["statetaxrate"];
            payload["common"]["distributionloanrate"] = estimatorData["distributionLoanRate"] || payload["common"]["distributionloanrate"];
            payload["common"]["afrrate"] = estimatorData["afrRate"] || payload["common"]["afrrate"];
            payload["common"]["arrangementfee"] = estimatorData["arrangementFee"] || payload["common"]["arrangementfee"];
            payload["stress"]["loanassumptions"]["discountcashvaluelowpoint"] = estimatorData["stressTestCashValueLowPoint"] || payload["stress"]["loanassumptions"]["discountcashvaluelowpoint"];
            payload["illustration"]["loanassumptions"]["discountcashvaluelowpoint"] = estimatorData["illustrationTestCashValueLowPoint"] || payload["illustration"]["loanassumptions"]["discountcashvaluelowpoint"];
            payload["stress"]["loanassumptions"]["bankdiscountfirstyear"] = estimatorData["stressBankDiscountFirstYear"] || payload["stress"]["loanassumptions"]["bankdiscountfirstyear"];
            if(createAllocationPayLoad){
                const allocationData = await createAllocationPayLoad(addPlus(values["plus"], values["InvestmentType"]), {InitEstimatorId:estimatorData.id , runTimeCarrierId:carrierId});
                const allocations = allocationData[0]["allocations"]
                const growthRate =  calculateGrowthRate(allocations);
                if(growthRate){
                    payload["common"]["growthrate"] = growthRate/100;
                    payload["common"]["distributionloanrate"] = growthRate - 0.50;
                }
                payload["illustration"]["interestrates"] =  allocationData;
                payload["comparison"]["interestrates"] =  allocationData;
                payload["stress"]["interestrates"] = isALGCarrier(carrierId) ? stressAllocationForALZ : stressAllocationsData;
            }
            // payload["stress"]["interestrates"][1].creditsuisserate = growthRate;
            // payload["stress"]["interestrates"][1].uspacesetterrate = growthRate;
            // payload["stress"]["interestrates"][3].creditsuisserate = growthRate;
            // payload["stress"]["interestrates"][3].uspacesetterrate = growthRate;
            // payload["stress"]["interestrates"][5].creditsuisserate = growthRate;
            // payload["stress"]["interestrates"][5].uspacesetterrate = growthRate;
            payload["illustration"]["loanassumptions"]["bankdiscountfirstyear"] = estimatorData["illustrationBankDiscountFirstYear"] || payload["illustration"]["loanassumptions"]["bankdiscountfirstyear"];
            payload["common"]["blendpercentage"] = estimatorData["estimatorDataBlend"] || payload["common"]["blendpercentage"];
        }
        payload["common"]["monthlypayclientextra"] = 0.05;
        payload["common"]["paymentfrequency"] = values.contributionFrequency;
        payload["common"]["paymentfrequencyemployer"] = "Annual";
        payload["common"]["carrier"] = carrierCode;

        await estimationActions.getEstimatesFromCloud(payload, role, {...values, issueAge: issueAge || client.issueAge, agentName: agentFullName, userName: clientFullName}, userId, callingFrom);
    };

    return [handleRunIllustration];
};

const Content: React.FC<ContentProps> = (props) => {
    const DEFAULT_MIN_CON: number = 21350;
    const classes = useStyles();
    const callingFrom = CallingFromObject.CLIENT_ILLUSTRATION;
    const initUPFormInit = {
        contributionAmount: "",
        contributionType: '',
        deathBenefitAmount: "",
        healthRating: "",
        incomeEndAge: "",
        incomeOptions: "",
        incomeStartAge: "",
        livingBenefit: [],
        tranche: "",
        contributionFrequency: ANNUALLY,
        contributionTypeToggle:"0",
        InvestmentType: ""
    };
    const {client, clientId, enrollmentId, fileName, proposalUpdate, setFileName, setHasReports , refreshList , setUploadProposalPopUp} = props;

    const validateObject = {
        tranche: yup.string().required('Required Field').nullable(),
        contributionType: yup.string().required('Required Field'),
        healthRating: yup.string().required('Required Field'),
        incomeOptions: yup.string().required('Required Field'),
        deathBenefitAmount: yup.string(),
        contributionFrequency: yup.string().required('Required Field'),
    };
    const uploadFormSchema = yup.object({
        ...validateObject,
        contributionAmount: yup.string(),
    });
    const runIllustrationFormSchema: any = yup.object(validateObject);

    const estimationActions = useActions(Actions);
    const accountActions = useActions(AccountActions);
    
    const loggedInUser = useSelector((store: RootState) => store.auth.user);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || DEFAULT_MIN_CON;
    const userDetail = useSelector((root: RootState) => root.clientAccount);
    const [uploadCustomOrRunIllustration, setUploadCustomOrRunIllustration] = React.useState<UCORIConstantKeyProps>(ProposalEnum.RUN_ILLUSTRATION);
    const [isIllustrationFilesCreated, setIllustrationFilesCreated] = React.useState(false);
    const [illustrationFiles, setIllustrationFiles] = React.useState();
    const [illustrationFileName, setIllustrationFileName] = React.useState(""); // client illustration
    const [issueAge, setIssueAge] = React.useState<number | null>(null);
    const [cashStream, setCashStream] = React.useState(false); // cash stream strategy
    const [employerOnly, setEmployerOnly] = React.useState(false); // employer only
    const [issuedCon, setIssueCon] = React.useState(true);
    const [isEstimationDataLoading, setIsEstimationDataLoading] = React.useState<boolean>(false);
    const [isReportRequestCompleted, setIsReportRequestCompleted] = React.useState<boolean>(false);
    const [requestId, setRequestId] = React.useState<null | string>(null);
    const [caseDetails, setCaseDetails] = React.useState<any>(null);
    const [isReportRequestAutomated, setIsReportRequestAutomated] = React.useState<boolean>(false);
    const [isReportDataLoading, setIsReportDataLoading] = React.useState<boolean>(false);
    const [upFormData, setUPFormData] = React.useState<any>(initUPFormInit);
    let atlantic = (ATLANTIC_HEALTH.includes(userDetail && userDetail.client && userDetail.client.company && userDetail.client.company.companyLinkUrl as any) && userDetail.role === 6)
    if (!loggedInUser) {
        console.info(isIllustrationFilesCreated, issuedCon, isReportRequestCompleted);
    }

    const extractCF = (caseDetails) =>{
        return (caseDetails.clientPolicies && caseDetails.clientPolicies.length > 0 && frequencyMappingObject[caseDetails.clientPolicies[0].paymentFrequency]) ? frequencyMappingObject[caseDetails.clientPolicies[0].paymentFrequency]  : ANNUALLY
    }
    const jVals = [
        {
            exactFileName: "Client Proposal - " + moment().format("DD-MM-YYYY HH:mm:ss"),
            fileName,
            name: "Upload Proposal",
            setFileName,
            showOnProposalUpdate: true, // TODO proposalUpdate,
            valName: "proposalFile",
        },
        {
            exactFileName: "Client Illustration - " + moment().format("DD-MM-YYYY HH:mm:ss"),
            fileName: illustrationFileName,
            name: "Upload Illustration",
            setFileName: setIllustrationFileName,
            showOnProposalUpdate: true, // TODO !proposalUpdate,
            valName: "illustrationFile",
        },
    ];

    const {
        UploadProposalFields,
        contributionTypeConditionalFields,
        incomeConditionalFields
    } = useFields({client, issueAge});

    const [handleRunIllustration] = useHandleSubmit({
        clientId,
        functionCallOnUUid: (uuid: string) => setRequestId(uuid),
        functionOnDataLoadingEnable: () => setIsEstimationDataLoading(true),
        callingFrom,
        issueAge
    });

    // upload proposal/illustration
    const handleUploadProposal = async (values: any) => {
            let issuedContribution = values.totalCombinedContribution ? values.totalCombinedContribution : getContributionAmount(values.contributionAmount,values.contributionFrequency); // contributionFrequency
            let employerContribution = Number(unmaskCurrency(values.employerContribution) || "");
            let deathBenefit = Number(unmaskCurrency(values.deathBenefitAmount.toString()));
            if (cashStream || employerOnly) {
                issuedContribution = 0;
            }
            if (proposalUpdate) {
                await accountActions.replaceProposalForAdvisor({
                    issuedContribution: issuedContribution,
                    employerContribution: employerContribution,
                    deathBenefit: deathBenefit,
                    fileName,
                    clientId
                });
            } else {
                await accountActions.uploadProposalForAdvisor({
                    issuedContribution: issuedContribution,
                    employerContribution: employerContribution,
                    deathBenefit: deathBenefit,
                    "fileName": {"uploadProposal": fileName, "uploadIllustration": illustrationFileName},
                    clientId,
                    agentId: caseDetails.agentId,
                    enrollmentId,
                    uploadType:uploadCustomOrRunIllustration,
                    actualHealthRating: values.healthRating,
                    livingBenefits: values.livingBenefit,
                    frequency:values.contributionFrequency 
                });
            }
            setUploadProposalPopUp(false);
            if (typeof refreshList === "function") {
                refreshList();
            }
    };

    const validate = (values: FormikValues) => {
        const healthRating = rateClassMappingObject[values.healthRating] ? rateClassMappingObject[values.healthRating] : rateClassMappingObject['default'];
        let minDeathBenefitAmount = 100000;
       
        if (caseDetails) {
            const minConPayload = {
                gender: caseDetails.gender,
                issueAge: Number(caseDetails.issueAge)
            };
            minDeathBenefitAmount = getMinimumDesireLifeTimeBenefits(minConPayload, healthRating);
        }
    
        const errors = isFormValid(values,{minContribution:minCon , minDeathBenefitAmount, atlantic });
        return errors.fieldsErrors
    };
    const getClientCaseDetails = async (clientId: number) => {
        const details = await accountActions.getCaseDetails(clientId);
        setCaseDetails(details);
    };
    const socketIsReportDataLoading = (value: any) => {
        setIsReportDataLoading(value);
        if (value === false) {
            setIllustrationFilesCreated(true);
            setUploadCustomOrRunIllustration(ProposalEnum.RUN_ILLUSTRATION as UCORIConstantKeyProps);
        }
    };
    const socketButtonClick = (loader: any) => {
        const companyLinkUrl = caseDetails && caseDetails.company && caseDetails.company.companyLinkUrl ;
        createEstimateReports({
            initRequest: () => {
                if (loader) {
                    setIsReportRequestAutomated(false);
                } else {
                    setIsReportRequestAutomated(true);
                }
            },
            onReportsAlreadyExist: (reports) => {
                setIsReportDataLoading(false);
                setIsReportRequestCompleted(true);
            },
            onRequestIdCreated: (id) => {
                setRequestId(id)
            },
            callingFrom,
            estimationActions,
            user: {
                loggerInUserId: loggedInUser.id,
                id: caseDetails && caseDetails.userId,
                role: caseDetails && caseDetails.user.role,
                companyLinkUrl
            }
        }).then();
    }

    const calculateIssueAge = async (dob: string, carrierId: number) => {
        const response: any = await estimationActions.getIssueAge(dob, carrierId);
        setIssueAge(response && response.issueAge);
    };

    const fetchCaseDetail = React.useCallback(() => {
        if (clientId) {
            getClientCaseDetails(clientId).then(() => {
            });
        }
    }, []);

    const firstTimerCall = async () => {
        fetchCaseDetail()
    };

    React.useEffect(() => {
        firstTimerCall().then();
    }, []);

    React.useEffect(() => {
        if (client && client.strategy && client.strategy.slug === cashStreamSlug) {
            setCashStream(true);
        }
        if (client && client.company && client.company.contributionType &&
            client.company.contributionType === "EMPLOYER_ONLY") {
            setEmployerOnly(true);
        }
    }, [client]);

    React.useEffect(() => {
        if (cashStream || employerOnly) {
            setIssueCon(false);
        }
    }, [cashStream, employerOnly]);

    React.useEffect(() => {
        if (caseDetails) {
            calculateIssueAge(caseDetails.dob, caseDetails.carrierId).then();
            const isAlZ =  getCarrierCode(caseDetails.carrierId) == "ALZ"
            const {trancheNo, contributionAmount, deathBenefit, actualHealthRating, livingBenefits} = caseDetails;
            setUPFormData({
                ...upFormData,
                tranche: trancheNo,
                contributionType: atlantic ? contributionOptions.deathBenefits.value : "",
                contributionAmount: (extractCF(caseDetails) === MONTHLY && contributionAmount) ? contributionAmount / 12 : contributionAmount,
                deathBenefitAmount: !!deathBenefit ? deathBenefit : "",
                healthRating: actualHealthRating ? actualHealthRating : "",
                livingBenefit: livingBenefits ? livingBenefits.map((s: any) => s.livingBenefitId) : [],
                contributionFrequency: extractCF(caseDetails),
                InvestmentType: isAlZ ? InvestmentValues.Conservative : InvestmentValues.SP500,
            });
        }
    }, [caseDetails, atlantic]);

    const formProps: any = {
        children: ProposalForm,
        contributionTypeConditionalFields,
        initialTouched:{totalCombinedContribution:true },
        enableReinitialize: true,
        enrollmentId,
        carrierId: caseDetails ? caseDetails.carrierId : 3,
        handleRunIllustration,
        incomeConditionalFields,
        initialValues: upFormData,
        illustrationFiles,
        isReportDataLoading,
        jVals,
        onSubmit: handleUploadProposal,
        setUploadCustomOrRunIllustration,
        uploadCustomOrRunIllustration,
        UploadProposalFields,
        validate: validate,
        validationSchema: uploadCustomOrRunIllustration === ProposalEnum.UPLOAD_CUSTOM_ILLUSTRATION ? uploadFormSchema : runIllustrationFormSchema,
    };

    return (
        <>
            {
                caseDetails &&
                <Socket
                    callingFrom={callingFrom}
                    handleButtonClick={socketButtonClick}
                    handleReset={noop}
                    handleResult={noop}
                    isReportRequestAutomated={isReportRequestAutomated}
                    page={"estimator"}
                    requestId={requestId}
                    setIsEstimationDataLoading={setIsEstimationDataLoading}
                    setIsReportDataLoading={socketIsReportDataLoading}
                    setIsReportRequestCompleted={setIsReportRequestCompleted}
                    setLifeDeathBenefitSeeking={noop}
                    setResponseData={noop}
                    userId={client && client.userId}
                    closeAfterFullProcess={true}
                />
            }
            {
                isEstimationDataLoading &&
                <Backdrop className={classes.backdrop} open={isEstimationDataLoading}>
                    <TestingProcessDialog
                        getEstimationProgress={estimationActions.getEstimationProgress}
                        handleClose={() => setIsEstimationDataLoading(false)}
                        isEstimationDataLoading={isEstimationDataLoading}
                        open={isEstimationDataLoading}
                    />
                </Backdrop>
            }

            <FormikWrapper {...formProps} />

            {
                (ProposalEnum.RUN_ILLUSTRATION === uploadCustomOrRunIllustration) &&
                <UploadedDocumentTable
                    enrollmentId={enrollmentId as string}
                    onfetchFiles={(reports: any) => setIllustrationFiles(reports)}
                    setHasReports={setHasReports}
                    reportsLoading={isReportDataLoading}
                    userId={client.userId}
                />
            }

        </>
    );
};

export default Content;
