import React, { useEffect } from "react";
import numeral from "numeral";
import { trustFeeText} from "../../../../../../constants";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import personalBetails from "../../../images/svg/personal-details.svg";
import {maskPhoneNumber} from "../../../../../../utils/commonUtils";
import incomeContributionAmount from "../../../images/svg/income-contribution-amount.svg";
import {useActions} from "../../../../../../actions";
import * as Actions from "../../../../../../actions/account";
import * as Estimation from "../../../../../../actions/estimation";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";

const PersonalDetailsDialog = (props: {
    openPersonalDialog: any,
    SetOpenPersonalDialog: any,
    contributionAmount: any,
    setBasicInfoStep:any,
    basicInfoStep?:any,
}) => {
    const accountActions = useActions(Actions);
    const estimationActions = useActions(Estimation);
    const [nationalLifeId, setNationalLifeId] = React.useState<number>(0);
    const {
        openPersonalDialog,
        SetOpenPersonalDialog,
        contributionAmount,
        basicInfoStep
    } = props;

    React.useEffect(() => {
        const getNatioanlLifeCarrierDetails = async () => {
            const nationalLifeDate = await accountActions.getNationalLifeDetails();
            if (nationalLifeDate && nationalLifeDate.nationalLife) {
                setNationalLifeId(nationalLifeDate.nationalLife.id);
            }
        };
        getNatioanlLifeCarrierDetails();
    }, []);
    const profile = useSelector((state: RootState) => state.profile);
    const { user: { client, role } } = useSelector((state: RootState) => state.auth);
    const { firstName, lastName, email, phoneNumber } = profile;
    const clientDetail: any = profile.client;
    const constextForm = useFormikContext();
    const checkStep = profile && profile.enrollment &&
                                        (
                                                (profile.enrollment.enrollmentStatus + "." + profile.enrollment.enrollmentSubStatus === "1.0") ||
                                                (profile.enrollment.enrollmentStatus + "." + profile.enrollment.enrollmentSubStatus === "1.52") ||
                                                (profile.enrollment.docusignStatus === -1) ||
                                                basicInfoStep >= 2.0
                                        );
    const [issueAge, setIssueAge] = React.useState(clientDetail && clientDetail.issueAge);

    const name = firstName + " " + lastName;
    const cellPhone = phoneNumber;
    const workPhone = (clientDetail && clientDetail.workPhoneNumber) || "";
    const gender = (clientDetail && clientDetail.gender) || "";
    const dob = (clientDetail && clientDetail.dob) || "";
    const carrierId = (clientDetail && clientDetail.carrierId) || "";
    const health = (clientDetail && clientDetail.healthType) || "";
    const ssn = (clientDetail && clientDetail.employeeSSN) || "";
    const identityTypeNo = (clientDetail && clientDetail.employeeIdNumber) || "";
    const selfEmployed = (clientDetail && clientDetail.selfEmployed) || false;
    const annualIncome = (clientDetail && clientDetail.annualIncome) || "";
    const employer = (clientDetail && clientDetail.employer) || "";
    const timeEmployed = (clientDetail && clientDetail.employedTime + " Years ") || "";
    const netWorth = (clientDetail && clientDetail.netWorth) || "";
    const occupation = (clientDetail && clientDetail.occupation) || "";
    const nonUsCitizen = (profile && profile.client && profile.client.nonUsCitizen) || "";

    const calculateIssueAge = async (dob, carrierId) => {
        const response: any = await estimationActions.getIssueAge(dob, carrierId);
        setIssueAge(response && response.issueAge);
    };
    useEffect(() => {
        calculateIssueAge(dob, carrierId)
    }, [dob]);

    return (
            <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openPersonalDialog}
                    aria-labelledby="Enrollment Home"
                    aria-describedby="Enrollment process"
            >
                <DialogTitle className="dialogHead">
                    <Typography variant="subtitle1" align="left" className="mb0"><strong>Basic
                        Info</strong></Typography>
                    <Typography variant="subtitle1" align="left" className="mb0">This is the information we sent
                        with your application. If this information is
                        incorrect please contact us at info@niwcorp.com or call <span
                                style={{display: "inline-flex"}}>(800) 294 - 9940</span> Ext 216.
                    </Typography>
                </DialogTitle>
                <DialogContent className="pr30 pl30">
                    <div id="alert-dialog-description" className="textCenter">
                        <span className="hLine mt0 mb0"/>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="right" className="textLight mt20">
                                    Personal details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={2}>
                                <img src={personalBetails as string} alt=""/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={10}>
                                <Grid item container>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Name:</strong> {name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Email:</strong> {email}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2" align="left"><strong> Cell
                                            Phone:</strong>{cellPhone ? maskPhoneNumber(cellPhone) : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2" align="left"><strong> Work
                                            Phone:</strong> {workPhone ? maskPhoneNumber(workPhone) : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Gender:</strong> {gender === 1 ? "Male" : "Female"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2" align="left"><strong> Date
                                            of
                                            Birth:</strong> {dob ? dob : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong> Insured Age:</strong> {issueAge > 0 ? issueAge : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Health: </strong>{health ? health : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong> Last 4 {nonUsCitizen === 1 ? "Social Security" : "ITIN"}:&nbsp;</strong>{ssn.slice(ssn.length - 4)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>DL No: </strong>{identityTypeNo ? identityTypeNo : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8}>
                                        <Typography className="mb10" variant="body2" align="left">
                                            <strong> Home Address: </strong>
                                            {(clientDetail && clientDetail.street + ", ") || ""}
                                            {(clientDetail && clientDetail.city + ", ") || ""}
                                            {(clientDetail && clientDetail.zipCode + ", ") || ""}
                                            {(clientDetail && clientDetail.state + ", ") || ""}
                                            {(clientDetail && clientDetail.currentResidenceCountry) || ""}
                                            {" "}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <span className="hLine mt0 mb0"/>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="right" className="textLight mt20 mb40">
                                    Income and Contribution Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={2}>
                                <img src={incomeContributionAmount as string} alt=""/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={10}>
                                <Grid item container>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Self
                                            Employed:</strong> {selfEmployed ? "Yes" : "No"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Your Annual
                                            Income:</strong> {annualIncome > 0 ? numeral(annualIncome).format("$0,0") : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Employer:</strong> {employer ? employer : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong> Household
                                            Income: </strong> {annualIncome > 0 ? numeral(annualIncome).format("$0,0") : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2"
                                                    align="left"><strong>Time
                                            Employed:</strong> {timeEmployed ? timeEmployed : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2" align="left"><strong> Your
                                            Net
                                            Worth:</strong> {netWorth > 0 ? numeral(netWorth).format("$0,0") : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2" align="left">
                                            <strong> {nationalLifeId && clientDetail && clientDetail.carrierId && nationalLifeId === clientDetail.carrierId ? "Industry and Occupation:" : "Occupation and specific duties:"} </strong> {occupation ? occupation : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography className="mb10" variant="body2" align="left">
                                            <strong>Yearly Contribution Amount: </strong>
                                            {contributionAmount ? numeral(Number(contributionAmount)).format("$0,0") + "*" : "NA"}
                                        </Typography>
                                        <Typography align="left" component="span" className="f12 floatLeft ">
                                            *Includes {role == 6 && client && client.employerContribution ? "employer contribution and" : ""} yearly
                                            trust fees of {trustFeeText(client)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    { !checkStep &&
                            <Button size="small" color="primary" variant="contained" className="mb20 mr30 mt15 floatRightSM w100sm mr0Small" onClick={() => {
                                SetOpenPersonalDialog(false);
                            }}>
                                Edit Information
                            </Button>
                    }
                    <Button size="small" color="primary" variant="contained" className="mb20 mr30 mt15 floatRightSM w100sm mr0Small"
                            type="submit"
                            onClick={(e) => {
                                if  (!checkStep && constextForm.submitForm) {
                                    constextForm.submitForm();
                                }

                                SetOpenPersonalDialog(false);
                                e.preventDefault()
                            }}
                    >
                        {!checkStep? `Confirm and Close`: `Close`}
                    </Button>
                </DialogContent>
            </Dialog>
    );
};

export default PersonalDetailsDialog;