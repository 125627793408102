import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, FormHelperText, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ErrorCommon, NumberTextField, TextFieldCommon } from "../../../../../components/formikFormInputs";
import EstimationFormContext from "../Context/EstimationFormContext";
import { Label } from "../../../../../components/formInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoIcon from "@material-ui/icons/Info";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { maskCurrency, unmaskCurrency } from "../../../../../utils/commonUtils";
import { useFormikContext } from "formik";
import EmployerContribution from "./EmployerContribution";
import ContributionFrequency, { ANNUALLY, MONTHLY } from "./ContributionFrequency";
import {getContributionFrequencyText} from "../../../../../utils/commonFunc";
import NewEmployerContribution from "./NewEmployerContribution";
import { useCheckAtlantic } from "../../../../../hooks/useAuth";
import { ATLANTIC_HEALTH } from "../../../../../constants";


export default function StepTwo(props: any) {
    const { callingFrom } = props;
    const { values, errors, touched, handleChange, setFieldValue, setFieldTouched }: any = useFormikContext();
    const [isShowingPotentialDetails, setIsShowingPotentialDetails] = React.useState<boolean>(false);
    const { user ,user : { accessType } } = useSelector((state: RootState) => state.auth);
    const companyLinkUrl = user && user.client && user.client.company && user.client.company.companyLinkUrl;
    const minCon = useSelector((state: RootState) => state.minimumContribution) || 21350;
    const atlantic = useCheckAtlantic();
    let fieldLabel: string = "What is Your Primary Plan Focus?";
    let deathBenefitProtectionLabel: string = "Maximize my death benefit protection";
    let potentialDistributionLabel: string = "Maximize my potential distributions";
    let contributionLabel: string = "How Much Would You Like to Contribute";
    let newemployerContribution: string = "Employer Contribution";
    let disablemonthly = companyLinkUrl === ATLANTIC_HEALTH[0];
    const onChangeFrequency = (frequency) => {
        let totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString()));
        if (frequency === ANNUALLY && values["annualContribution"]) {
            totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString())) * 12
        }
        if (frequency === MONTHLY && values["annualContribution"]) {
            totalContributionAmmount = Number(unmaskCurrency(values["annualContribution"].toString())) / 12
        }
        setFieldValue("annualContribution", Math.round(totalContributionAmmount));
    };

    useEffect(() => {
        setFieldTouched("annualContribution", true, true);
    }, [minCon]);

    switch (callingFrom) {
        case "CUSTOM_DESIGN":
            fieldLabel = "What is the Primary Plan Focus?";
            deathBenefitProtectionLabel = "Maximize death benefit protection";
            potentialDistributionLabel = "Maximize potential distributions";
            contributionLabel = "Set the annual contribution amount";
    }
    useEffect(() => {
        if(atlantic){
            disablemonthly && setFieldValue('contributionFrequency', MONTHLY)
            setFieldValue('contributionType', "1")
            setFieldValue("primaryFocusPlan", "contributionAmount");
        }
    }, [atlantic]);
    let helperText = `Minimum ${maskCurrency(minCon + "")} ${getContributionFrequencyText(values["contributionFrequency"])} contribution required`;
    if (values["contributionFrequency"] === MONTHLY) {
        helperText = `Minimum ${maskCurrency(minCon + "")} ${getContributionFrequencyText(values["contributionFrequency"])} contribution required. For monthly designs, two months of payments are due at signing.`;
    }
    //Commented for now as per kyle text updates
    //12 ${getContributionFrequencyText(values["contributionFrequency"])} payments of ${maskCurrency(minCon + "")} is ${maskCurrency(minCon*12 + "")}. Additional $1,350 annual trust due at signing.

    const annualContributionField = {
        id: "annualContribution",
        name: "annualContribution",
        label: <Label label={ getContributionFrequencyText(values["contributionFrequency"], true) + " Contribution (For 5 Years)"} required={true}/>,
        component: NumberTextField,
        variant: "outlined",
        className: "fullWidthcustomFields",
        InputProps: {
            startAdornment: <InputAdornment className="margin0 dollerIcon"
                                            position="start">$</InputAdornment>,
        },
        helperText: ((values && values.employerContribution === "1") || atlantic ) ? "" : helperText,
    };

    const handleRadioChange = async (event: any, setFieldValue: Function, setFieldTouched: any) => {
        if ((event.target as HTMLInputElement).value === "deathBenefit") {
            await setFieldValue("primaryFocusPlan", (event.target as HTMLInputElement).value);
            setIsShowingPotentialDetails(false);
            await setFieldValue("annualContribution", "");
        } else if ((event.target as HTMLInputElement).value === "contributionAmount") {
            await setFieldValue("primaryFocusPlan", (event.target as HTMLInputElement).value);
            setIsShowingPotentialDetails(true);
            setFieldTouched("annualContribution", true, true);
        }
    };

    return (
            <EstimationFormContext.Consumer>
                {
                    () => {

                        return (
                                <FormControl>
                                    <Typography className="mb20 mt15" variant="body1"
                                                component="strong">{fieldLabel}<sup className="colorRed">*</sup>
                                        <Tooltip color="primary"
                                                 title="Maximizing death benefit will aim to keep the death benefit
                                                 component of your policy at or above its initial amount; no income
                                                 will be shown in order to illustrate the maximum amount of death
                                                 benefit at age 90. Maximizing potential distributions will
                                                                    illustrate the highest possible
                                                                    distributions without lapsing the
                                                                    policy during the years you wish to
                                                                    receive income. The distributions will
                                                                    lower the overall death benefit."
                                                 arrow>
                                            <IconButton aria-label="Info">
                                                <InfoIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <RadioGroup aria-label="quiz" value={values && values.primaryFocusPlan}
                                                onChange={(e) => {
                                                    handleRadioChange(e, setFieldValue, setFieldTouched).then(() => {
                                                    });
                                                }}>
                                        {!atlantic && <Card className="borderRadius mb20 boxShadowNone border1px">
                                            <CardContent>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <FormControlLabel className="mb0"
                                                                      value="deathBenefit"
                                                                      control={<Radio className="pd0 ml15"/>}
                                                                      label={""}/>
                                                    <span><Typography
                                                            variant="body1"
                                                            className="mb0">{deathBenefitProtectionLabel}</Typography>
                                                        <Typography variant="caption">Includes max death benefits but no annual
                                                    distributions.</Typography>
                                                    </span>
                                                </div>
                                            </CardContent>
                                        </Card> }
                                        <Card className="mb20 boxShadowNone border1px">
                                            <CardContent>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <FormControlLabel className="mb0" value="contributionAmount"
                                                                      control={<Radio className="pd0 ml15"/>}
                                                                      label={""}/>
                                                    <span><Typography
                                                            variant="body1"
                                                            className="mb0">{potentialDistributionLabel}</Typography>
                                                        <Typography variant="caption">Includes max annual distributions but reduces death
                                                        benefits.</Typography>
                                                    </span>
                                                </div>
                                                {
                                                        (isShowingPotentialDetails || (values && values.primaryFocusPlan === "contributionAmount")) &&
                                                        <>
                                                            <Grid
                                                                    className="mb15"
                                                                    item
                                                                    key={annualContributionField.id}
                                                                    xs={12}
                                                            >
                                                                <Typography
                                                                        className="mb20 mt15 floatLeft w100"
                                                                        component="strong"
                                                                        variant="body1"
                                                                >
                                                                    {contributionLabel}
                                                                </Typography>
                                                                <ContributionFrequency onChange={(frequency) => {
                                                                    onChangeFrequency(frequency)
                                                                }} monthlyDisabled={atlantic && disablemonthly} />
                                                                <TextFieldCommon
                                                                        {...annualContributionField}
                                                                        name={annualContributionField.name}
                                                                        values={values}
                                                                        onChange={handleChange}
                                                                />
                                                                <ErrorCommon
                                                                        errors={errors}
                                                                        name={annualContributionField.name}
                                                                        touched={touched}/>
                                                            </Grid>
                                                            {
                                                                    (values && values.employerContribution === "1") &&
                                                                    <EmployerContribution helperText={helperText} />
                                                            }

                                                           {((accessType === "client" && values['contributionFrequency'] === "Annual") || atlantic) &&  <Grid className="mb15" item xs={12}>
                                                                <Typography
                                                                        className="mb20 mt15 floatLeft w100"
                                                                        component="strong"
                                                                        variant="body1"
                                                                >
                                                                    {newemployerContribution}
                                                                </Typography>
                                                                <NewEmployerContribution atlantic={atlantic}   />
                                                            </Grid>}
                                                        </>

                                                }

                                            </CardContent>
                                        </Card>
                                        {errors && errors.primaryFocusPlan && <FormHelperText className="f12"
                                                                                              style={{ color: "red" }}>{errors.primaryFocusPlan}</FormHelperText>}
                                    </RadioGroup>
                                </FormControl>
                        );
                    }
                }
            </EstimationFormContext.Consumer>
    );
}
