import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import { NumberTextField, TextField } from "../../../../../../components/formikFormInputs";
import { allFieldsArr, AllFields } from "./IndividualIncome";
import { isLongForm, isWorksheet } from "../../EnrollmentApplication/Utilities";
import { offerType } from "../../../../../../reducers/carrier";
import { showLabelTextByStatus } from "../../../../../../utils/commonUtils";

type HouseHoldIncomeProp = {
    conditionalRequired: boolean,
    CommonField: Function,
    handleChange: Function,
    handleBlur: Function,
    setFieldValue: Function,
    touched: any,
    errors: any,
    values: any,
    offerType: string,
}

function HouseHoldIncome(props: HouseHoldIncomeProp) {
    const {
        conditionalRequired,
        CommonField,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        errors,
        offerType
    } = props;
    const lastYearArr = allFieldsArr(conditionalRequired);
    const houseHoldYearsArr = [
        { label: (<>Household Income <strong>last year</strong></>), name: "LastYear" },
        { label: (<>Household Income <strong>1 year prior</strong></>), name: "1YearsPrior" },
        { label: (<>Household Income <strong>2 year prior</strong></>), name: "2YearsPrior" },
    ];
    const otherIncome = [{
        name: "houseHoldOtherIncomeDetail",
        label: <Label label={"Other Income Details"} required={false}/>,
        isRequired: false,
        component: TextField,
        variant: "outlined",
        className: "TextFieldWidth",
    }];

    const incomeFields: any[] = [
        {
            id: "householdNetWorth",
            name: "householdNetWorth",
            label: <Label label={showLabelTextByStatus(values["marriageStatus"] , "Married","Household Net Worth", "Individual / Household Net Worth" )} required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
            show: true,
        },
        {
            id: "spouseDUAIncome",
            name: "spouseDUAIncome",
            label: <Label label={"Spouse's / Domestic Partner's / Civil Union Partner's Annual income"}
                          required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
            show: isLongForm(offerType) || isWorksheet(offerType),
        },
        {
            id: "householdLiquidAssets",
            name: "householdLiquidAssets",
            label: <Label label={showLabelTextByStatus(values["marriageStatus"] , "Married","Household Liquid Assets", "Individual / Household Assets" )} required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
            show: isLongForm(offerType) || isWorksheet(offerType),
        },
        {
            id: "householdAnnualExpenses",
            name: "householdAnnualExpenses",
            label: <Label label={ showLabelTextByStatus(values["marriageStatus"] , "Married","Household Annual Expenses", "Individual / Household Expenses" ) } required={true} />,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "TextFieldWidth dollerIcon",
            dollarSign: true,
            show: isLongForm(offerType) || isWorksheet(offerType),
        }];
     return <>
        <Grid item xs={12}>
            <Grid container spacing={3} className="mt15">
                {
                        values["marriageStatus"] === "Married" &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6" component="strong" className="w100">Household
                                    Income</Typography>
                            </Grid>
                            <AllFields lastYearArr={lastYearArr} CommonField={CommonField} values={values}
                                       errors={errors} handleChange={handleChange} handleBlur={handleBlur}
                                       setFieldValue={setFieldValue} yearsArr={houseHoldYearsArr} touched={touched}
                                       prefixText="houseHoldIncome"
                            />
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {
                                        otherIncome.map((field, index) => {
                                            return (
                                                    <Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
                                                        <div className="mb15">
                                                            {
                                                                CommonField(
                                                                        field,
                                                                        {
                                                                            values,
                                                                            errors,
                                                                            touched,
                                                                            handleChange,
                                                                            handleBlur,
                                                                            setFieldValue,
                                                                        },
                                                                )
                                                            }
                                                        </div>
                                                    </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </>
                }
                <Grid item xs={12}>
                    <Typography variant="h6" component="strong" className="mb0 w100 floatLeft">{
                        showLabelTextByStatus(values["marriageStatus"] , "Married","Household Net Worth", "Individual / Household Networth" )
                    }
                    </Typography>
                </Grid>
                {
                    incomeFields.map((field, index) => {
                        return (
                                field["show"] && <Grid item xs={12} md={6}  key={index + field["name"]}>
                                    <div>
                                        {
                                            CommonField(
                                                    field,
                                                    {
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        setFieldValue,
                                                    },
                                            )
                                        }
                                    </div>
                                </Grid>
                        );
                    })
                }
                <Grid item xs={12}>
                    <div className="hLine"/>
                </Grid>
            </Grid>
        </Grid>
    </>;
}

export default HouseHoldIncome;